<template>
  <div class="profitList" v-loading="loading">
    <!-- 头部 -->
    <header>
      <img src="@image/myEarningsBg.png" alt="我的收益背景" />
      <div class="left">
        <span class="amount">总收益：￥{{ total_profit }}</span>
        <span class="now_profitAmount">今日收益：￥{{ now_profit }}</span>
      </div>
      <div class="right">
        <span class="remark">可提现</span>
        <span class="amount">￥{{ surplus }}</span>
        <span class="button" @click="goSurplus"> 去提现 </span>
      </div>

      <div @click="getRuleText" class="rule">
        提现规则<i class="el-icon-arrow-right"></i>
      </div>
    </header>

    <!-- 月份 -->
    <div class="filter">
      <div class="left">
        <span class="title">收益明细</span>

        <!-- 按时间段选择 -->
        <el-date-picker
          popper-class="pickerDateRange"
          v-model="pickerDateRange"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始"
          end-placeholder="结束"
          :clearable="false"
          :editable="false"
          @change="upRange"
          :append-to-body="false"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <i class="el-icon-caret-bottom"></i>

        <span class="earningsThisMonth">收益：¥{{ month_profit }}</span>
        <span class="withdrawaThisMonth">提现：¥{{ month_drawings }}</span>
      </div>

      <div class="right">
        <!-- 全部创作 -->
        <el-select
          @change="upCreate"
          v-model="createValue"
          placeholder="创作收益"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in createOption"
            :key="item.type"
            :label="item.name"
            :value="item.type"
          >
          </el-option>
        </el-select>

        <!-- 全部调研 -->
        <el-select
          @change="upResearch"
          v-model="researcValue"
          :disabled="disabledResearch"
          placeholder="调研收益"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in researcOption"
            :key="item.type"
            :label="item.name"
            :value="item.type"
          >
          </el-option>
        </el-select>

        <!-- 提现 -->
        <el-button
          :type="primary"
          @click="getWithdrawalList"
          class="profit_button"
          >提现记录</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      v-loading="loading"
      class="table"
      :data="surveyProfit"
      style="width: 100%"
    >
      <el-table-column label="编号">
        <template slot-scope="scope">
          <span style="display: block">
            {{
              scope.row.flag == 1 && scope.row.status == 1
                ? scope.row.pid_info
                : ""
            }}
            <span v-if="scope.row.tel">-{{ scope.row.tel }}</span>
          </span>
        </template>
      </el-table-column>

      <el-table-column label="项目名称">
        <template slot-scope="scope">
          <span
            @click="
              showEntryDetail(scope.row.status);
              details = scope.row;
            "
            style="display: block; cursor: pointer"
          >
            {{
              scope.row.flag == 1
                ? scope.row.pro_name || "暂无项目名称"
                : "提现"
            }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="交易方式">
        <template slot-scope="scope">
          <span style="display: block">
            {{ scope.row.payment_type_name }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="收益金额">
        <template slot-scope="scope">
          <span style="display: block">
            {{ scope.row.flag == 1 ? "+" : "-" }}{{ scope.row.point }}￥</span
          >
        </template>
      </el-table-column>

      <el-table-column prop="create_time" label="日期">
        <template slot-scope="scope">
          <span>{{ scope.row.add_time }}</span>
        </template>
      </el-table-column>

      <el-table-column label="交易状态">
        <template
          slot-scope="scope"
          v-if="scope.row.status == 1 || scope.row.flag == 0"
        >
          <span style="display: block" :style="scope.row.type | typeColor">
            {{ scope.row.type | typeName }}</span
          >
          <el-button
            round
            size="mini"
            class="billBtn"
            @click="
              recordDetails = true;
              details = scope.row;
            "
            >账单详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      hide-on-single-page
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-size="page_size"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!-- 提现规则对话框 -->
    <el-dialog
      :title="ruleTitle"
      custom-class="rules"
      :visible.sync="rulesVisible"
      width="30%"
    >
      <div class="close" @click="rulesVisible = false">
        <img src="@image/closebill.png" alt="" />
      </div>
      <h4>{{ ruleTitle }}</h4>
      <div class="word" v-html="ruleContent"></div>
    </el-dialog>

    <!-- 收款记录详情对话框 -->
    <el-dialog
      title="收款记录详情"
      custom-class="back-box"
      :visible.sync="recordDetails"
      width="40%"
    >
      <div class="closebill" @click="recordDetails = false">
        <img src="@image/closebill.png" alt="" />
      </div>

      <div class="box-chin">
        <div class="title" :style="details.type | typeColor">
          {{ details.type | detailName }}
        </div>
        <div class="dateils_price">
          {{ details.flag == 1 ? "+" : "-" }} ¥{{ details.point }}
        </div>
      </div>
      <div class="chin-zi">
        <div class="left-title">项目编号</div>
        <div v-if="details.flag == 1" class="right-text">
          {{ details.pid_info }}
        </div>
        <div
          v-else
          @click="goWithdrawalDetails(details.pid)"
          style="cursor: pointer"
          class="right-text"
        >
          提现
          <i
            style="font-size: 16px; font-weight: 700"
            class="el-icon-arrow-right"
          ></i>
        </div>
      </div>
      <div class="chin-zi" v-show="details.flag == 1">
        <div class="left-title">项目名称</div>
        <div class="right-text">{{ details.pro_name }}</div>
      </div>
      <div class="chin-zi">
        <div class="left-title">交易方式</div>
        <div class="right-text">
          {{ details.payment_type_name }}
          <div style="margin-top: 10px">{{ details.payment_account }}</div>
        </div>
      </div>
      <div class="chin-zi">
        <div class="left-title">交易时间</div>
        <div class="right-text">{{ details.add_time | timeFormat }}</div>
      </div>
      <div class="chin-zi">
        <div class="left-title">交易单号</div>
        <div class="right-text">{{ details.transaction_no || "暂无" }}</div>
      </div>
      <div v-if="details.error_reason" class="chin-zi">
        <div class="left-title">失败原因</div>
        <div style="color: #ff0000" class="right-text">
          {{ details.error_reason }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <a
          target="_blank"
          v-show="details.payment_fail_link"
          :href="details.payment_fail_link"
        >
          <el-button round type="primary">去修改</el-button>
        </a>
      </span>
    </el-dialog>

    <!-- 创作收益对话框 -->
    <el-dialog
      title="创作收益"
      custom-class="creative"
      :visible.sync="creativeVisible"
      width="40%"
    >
      <div class="close" @click="creativeVisible = false">
        <img src="@image/closebill.png" alt="" />
      </div>

      <h4>{{ details.pro_name ? details.pro_name : details.source_name }}</h4>

      <div class="detail">
        <p>{{ details.add_time }}</p>
        <p>￥+{{ details.point }}</p>
      </div>
      <div class="word">
        {{ details.pro_name }}
      </div>

      <div class="btn">
        <el-button type="primary" @click="creativeVisible = false" round
          >知道了</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "profitList",
  data() {
    return {
      createValue: 0,
      createOption: [
        { name: "全部创作", type: 0 },
        { name: "病例分享", type: 2 },
        { name: "学术视频", type: 3 },
        { name: "指南解析", type: 4 },
        { name: "发布论文", type: 11 },
        { name: "发布问答", type: 5 },
      ],
      creativeVisible: false,
      details: {}, //  账单详情对话框数据
      disabledResearch: false,
      loading: true,
      month_profit: 0, // 当月收益
      month_drawings: 0, // 当月提现金额
      now_profit: 0, // 今日收益
      page: 1, // 页码
      page_size: 50, // 每页容量
      pickerDateRange: [],
      // 选择时间不能超过当日的日期
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      primary: "",
      researcValue: 0,
      researcOption: [
        { name: "全部调研", type: 0 },
        { name: "在线调研", type: 1 },
        { name: "电话调研", type: 2 },
        { name: "面访调研", type: 3 },
      ],
      rulesVisible: false,
      ruleTitle: "",
      ruleContent: "",
      recordDetails: false, // 账单详情对话框的显示
      surplus: 0, // 可提现
      surveyProfit: [], // 收益列表
      total_profit: 0, // 总收益
      total: 300, // 总条数
      day: new Date().getDate(), // 日
      month: new Date().getMonth() + 1, //月
      year: new Date().getFullYear(), //年
      start_time: "", // 筛选开始时间
      end_time: "", // 筛选结束时间
    };
  },

  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },

  // 数据过滤
  filters: {
    // 支付状态
    typeName(val) {
      switch (val) {
        case 1:
          val = "待支付";
          break;
        case 2:
          val = "交易成功";
          break;
        case 3:
          val = "信息有误";
          break;
      }
      return val;
    },

    // 详情单显示
    detailName(val) {
      switch (val) {
        case 1:
          val = "待支付";
          break;
        case 2:
          val = "交易成功";
          break;
        case 3:
          val = "交易失败";
          break;
      }
      return val;
    },

    // 支付状态颜色
    typeColor(val) {
      switch (val) {
        case 1:
          val = "color:#fc6f0e";
          break;
        case 2:
          val = "color:#000";
          break;
        case 3:
          val = "color:#ff0000";
          break;
      }
      return val;
    },
  },

  created() {
    this.getCurrentProfit();
    this.getProfitNew();
  },

  methods: {
    // 提现规则
    async getRuleText() {
      this.rulesVisible = true;
      let res = await this.$userApi.getRuleText({
        type: 1,
      });
      if (res.code == 200) {
        this.ruleTitle = res.data.name;
        this.ruleContent = res.data.content;
      } else {
        this.$message.error(res.message);
      }
    },

    // 默认获取近半年收益
    getCurrentProfit() {
      let { year, month, day } = this;
      let prev_year; // 开始年
      let prev_month; // 开始月
      if (month - 6 < 0) {
        prev_year = year - 1;
        prev_month = 12 + month - 6;
      } else {
        prev_year = year;
        prev_month = month - 6;
      }

      // 开始日期
      this.start_time =
        prev_year +
        "-" +
        this.fillingZero(prev_month) +
        "-" +
        this.fillingZero(day);

      // 结束日期
      this.end_time =
        year + "-" + this.fillingZero(month) + "-" + this.fillingZero(day);

      this.pickerDateRange = [this.start_time, this.end_time];
      this.getProfitNew();
    },

    // 按时间段选择
    upRange() {
      this.start_time = this.pickerDateRange[0];
      this.end_time = this.pickerDateRange[1];
      this.page = 1;
      this.getProfitNew();
    },

    // 获取收益列表
    /* flag: 1(收益)  0(提现)
     * status:1(调研) 2(网医创作收益)
     * */
    async getProfitNew() {
      let token = localStorage.getItem("token");
      let { code, data } = await this.$userApi.getProfitNew({
        token,
        type: 4,
        flag: this.flag, // 收益和提现
        pro_type: this.pro_type, // 调研类型
        profit_type: this.profit_type, // 创作类型
        profit_time_start: this.start_time, // 开始时间
        profit_time_end: this.end_time, // 结束时间
        pages: this.page - 1, // 页码
      });
      this.loading = false;
      if (code == 200) {
        this.surveyProfit = data.list;
        this.total_profit = data.total_profit;
        this.surplus = data.surplus;
        this.cashable = +data.surplus;
        this.now_profit = data.now_profit;
        this.month_profit = data.month_profit;
        this.month_drawings = data.month_drawings;
        this.total = data.list.length;
        this.page_size = data.page_size;
      } else {
        this.$message.error(data.message);
      }
    },

    // 创作筛选
    upCreate() {
      this.researcValue = "";
      this.pro_type = "999"; // 调研筛选条件为空
      this.page = 1;
      if (this.createValue == 0) {
        this.profit_type = "";
        this.getProfitNew();
      } else {
        this.profit_type = this.createValue;
        this.getProfitNew();
      }
    },

    // 调研筛选
    upResearch() {
      this.createValue = "";
      this.profit_type = "999";
      this.page = 1;
      if (this.researcValue == 0) {
        this.pro_type = "";
        this.getProfitNew();
      } else {
        this.pro_type = this.researcValue;
        this.getProfitNew();
      }
    },

    // 提现列表
    getWithdrawalList() {
      this.researcValue = ""; // 调研筛选条件为空
      this.pro_type = "999";
      this.page = 1;
      if (this.primary) {
        this.primary = "";
        this.flag = "";
        this.disabledResearch = false;
        this.getProfitNew();
      } else {
        this.flag = 0;
        this.primary = "primary";
        this.disabledResearch = true;
        this.getProfitNew();
      }
    },

    // 分页
    handleCurrentChange() {
      this.getProfitNew();
    },

    // 项目对话框
    showEntryDetail(status) {
      if (status == 2) {
        this.creativeVisible = true;
      }
    },

    // 去提现
    goSurplus() {
      this.$emit("goSurplus");
    },

    // 去账单页面
    goWithdrawalDetails(id) {
      this.$emit("goWithdrawalDetails");
      localStorage.setItem("detail_id", id);
    },

    // 补0
    fillingZero(val) {
      return val > 9 ? val : "0" + val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

// 收益列表内容
.profitList {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px 27px 20px;

  header {
    height: 196px;
    box-sizing: border-box;
    padding: 42px 70px;
    display: flex;
    position: relative;
    overflow: hidden;

    > img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    > .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: auto;

      > .amount {
        min-width: 370px;
        height: 67px;
        border: 1px solid #f2f2f2;
        border-radius: 6px;
        font-size: 40px;
        line-height: 65px;
        color: white;
        padding: 10px;
        font-weight: bold;
        text-align: left;
      }
      > .now_profitAmount {
        font-size: 18px;
        color: white;
        margin-top: 20px;
      }
    }
    > .right {
      display: flex;
      flex-direction: column;
      justify-content: center;

      > .remark {
        font-size: 18px;
        color: #ffffff;
        padding-left: 10px;
      }
      > .amount {
        font-size: 48px;
        line-height: 56px;
        color: white;
      }
      > .button {
        cursor: pointer;
        margin-top: 10px;
        height: 50px;
        min-width: 195px;
        border-radius: 50px;
        background-color: white;
        color: #ff8307;
        font-size: 24px;
        text-align: center;
        line-height: 50px;
      }
    }

    .rule {
      cursor: pointer;
      color: #fff;
      background: #f28d08;
      font-size: 16px;
      width: 120px;
      height: 60px;
      line-height: 75px;
      border-radius: 15px;
      text-align: center;
      position: absolute;
      right: 20px;
      top: -20px;

      i {
        font-weight: 700;
        margin-left: 2px;
      }
    }
  }

  .filter {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;

    .title {
      font-size: 24px;
      line-height: 24px;
      color: #333333;
    }

    .el-icon-caret-bottom {
      left: -24px;
      font-size: 14px;
      color: #8f8f8f;
      z-index: 9;
    }

    .earningsThisMonth {
      font-size: 12px;
      color: #8f8f8f;
      z-index: 9;
      margin-right: 10px;
    }

    .withdrawaThisMonth {
      font-size: 12px;
      color: #8f8f8f;
      z-index: 9;
    }
  }

  .table {
    margin-top: 10px;
    min-height: 500px;

    .billBtn {
      margin-top: 5px;
      color: $--themeColor;
      border-color: $--themeColor;
    }
  }
}

::v-deep {
  thead {
    tr {
      font-size: 16px;
      line-height: 22px;
      color: #333333;

      > th.is-leaf {
        background: #bababa;
        color: #fff;
        height: 43px;
        text-align: center;
        font-weight: 500;
      }

      > td {
        box-sizing: border-box;
        padding: 20px 0;
        text-align: center;
      }
    }
  }

  tbody {
    > tr {
      td {
        text-align: center !important;
      }
    }
  }

  .pickerDateRange {
    tr {
      th {
        height: 35px;
        padding: 0;
      }
      td {
        padding: 5px 0;
      }
    }
  }

  // 日期时间段选择器样式
  .el-date-editor--daterange {
    z-index: 1;
    height: 30px;
    box-sizing: border-box;
    border-radius: 30px;
    border: 1px solid #8f8f8f;
    background: none;
    width: max-content;
    margin-left: 14px;

    .el-range-separator {
      height: 32px;
    }

    .el-range__close-icon {
      width: 8px;
    }

    .el-range-input {
      width: 75px;
      padding: 0 3px;
    }

    .el-icon-date {
      display: none;
    }
  }

  // 下拉选择器
  .el-select {
    margin-left: 10px;

    .el-input__inner {
      height: 26px;
      line-height: 26px;
      width: 110px;
      border-radius: 30px;
      border: 1px solid #8f8f8f;
      box-sizing: border-box;
      font-size: 14px;
    }

    .el-select__caret {
      line-height: 26px;
    }
  }

  .profit_button {
    height: 26px;
    padding: 0 20px;
    margin-left: 10px;
  }

  .el-pagination {
    text-align: center;
    margin-top: 15px;
  }
}

// 提现规则对话框
/deep/.rules {
  border-radius: 15px;
  padding: 20px 40px 80px;

  .el-dialog__body {
    position: relative;
  }

  h4 {
    padding-top: 40px;
    font-weight: 500;
    color: #333333;
    font-size: 24px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid #eaeaea;
  }

  .close {
    position: absolute;
    right: -20px;
    top: -10px;
    width: 25px;
    cursor: pointer;

    img {
      width: 100%;
      cursor: pointer;
    }
  }

  .word {
    color: #000;
    font-size: 16px;
    line-height: 26px;
    text-align: justify;
  }
}

// 创作收益对话框
/deep/.creative {
  border-radius: 15px;
  padding: 20px 40px 80px;

  .el-dialog__body {
    position: relative;
  }

  h4 {
    text-align: center;
    padding-top: 40px;
    font-weight: 500;
    color: #333333;
    font-size: 24px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid #eaeaea;
  }

  .close {
    position: absolute;
    right: -20px;
    top: -10px;
    width: 25px;
    cursor: pointer;

    img {
      width: 100%;
      cursor: pointer;
    }
  }

  .detail {
    padding: 25px 0 15px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    color: #03dbc6;
  }

  .word {
    color: #000;
    font-size: 16px;
    line-height: 26px;
    text-align: justify;
    padding: 20px 0 50px;
    border-bottom: 2px solid #eaeaea;
  }

  .btn {
    text-align: center;

    button {
      margin-top: 20px;
      width: 150px;
      font-size: 18px;
    }
  }
}

// 账单详情对话框
/deep/.back-box {
  position: relative;
  border-radius: 15px;

  .el-dialog__body {
    box-sizing: border-box;
    padding: 50px 50px;

    .title {
      font-size: 24px;
      color: #333333;
      margin-bottom: 30px;
    }
  }

  .el-dialog__footer {
    text-align: center;

    button {
      width: 150px;
    }
  }

  .closebill {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;

    img {
      width: 100%;
      cursor: pointer;
    }
  }

  // 账单详情对话框头部
  .box-chin {
    text-align: center;
    .title {
      max-width: 20px 0 7px 0;
      font-size: 18px;
    }
    .dateils_price {
      font-size: 26px;
      font-weight: bold;
    }
  }

  // 账单详情内容
  .chin-zi {
    padding: 20px 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;

    .left-title {
      color: #333;
      font-weight: 400;
    }

    .el-input__inner {
      border: none;
      text-align: right;
    }

    .el-input.is-disabled .el-input__icon {
      cursor: default;
    }

    .el-input.is-disabled .el-input__inner {
      color: #606266;
      background-color: #0000;
      cursor: default;
    }

    .right-text {
      flex: 1;
      text-align: right;
    }

    .right-box {
      flex: 1;
      display: flex;
      align-items: center;
      color: #8f8f8f;

      .code-num {
        cursor: pointer;
        margin-left: 10px;
        color: $--themeColor;
      }
    }
  }
}
</style>