<template>
  <div class="learningTrack">
    <header>学习轨迹</header>
    <div class="content" v-loading="loading">
      <div class="learningTrack-one">
        <div class="learningTrackLeft">
          <img class="learningTrack-avatar" :src="userInfo.avatar" />
          <div class="learningTrack-right">
            <span>学习时长</span>
            <span class="learningTrack-time"
              >{{ timeTotal[0] }}′ {{ timeTotal[1] }}″</span
            >
          </div>
        </div>
        <div class="progressBarBox">
          <div
            class="progressBar"
            v-for="(item, index) in markList"
            :key="index"
          >
            <el-progress
              type="circle"
              color="#03DBC6"
              :percentage="item.precent"
              :width="84"
            ></el-progress>
            <span class="progressBar-profession">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="learningTrack-course">
        <div
          class="course-content"
          v-for="(item, index) in markList"
          :key="index"
        >
          <span class="content-title">{{ item.name }}</span>
          <div
            class="courseDetails"
            v-for="(it, i) in item.catalog"
            :key="i"
            @click="
              xin.goUrl(
                `/index-eLearning?book_uid=${item.book_uid}&catalog_uid=${it.catalog_uid}`
              )
            "
          >
            <div class="border" :class="{ border1: it.study_status }">
              <div
                class="border-round"
                :class="{ 'border-round1': it.study_status }"
              ></div>
            </div>
            <span class="courseDetails-time">{{ it.create_time }}</span>
            <span>{{ it.name.trim().split(/\s+/)[1] }}</span>
            <span class="point"
              >…………………………………………………………………………………………………………………………………………………………………………………………………………</span
            >
            <span
              ><span v-if="it.hour[0] != '00'">{{ it.hour[0] }}′</span>
              {{ it.hour[1] }}″</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      list: [
        {
          profession: "内分泌专业",
        },
        {
          profession: "人文医学",
        },
        {
          profession: "医保政策",
        },
      ],
      list1: [
        {
          contentTitle: "内分泌专业",
          profession: [
            { time: "12-30", name: "解剖学", number: "3′" },
            { time: "12-30", name: "生理学", number: "20′" },
            { time: "12-30", name: "生物化学", number: "35′" },
            { time: "12-30", name: "病理学", number: "53′" },
            { time: "12-29", name: "医学微生物学", number: "53′" },
            { time: "12-29", name: "医学免疫学", number: "53′" },
            { time: "12-29", name: "药理学", number: "53′" },
            { time: "12-29", name: "学免疫学", number: "53′" },
            { time: "12-28", name: "药理学", number: "53′" },
            { time: "12-28", name: "药理学", number: "53′" },
            { time: "12-28", name: "药理学", number: "53′" },
          ],
        },
        {
          contentTitle: "人文医学",
          profession: [
            { time: "12-30", name: "解剖学", number: "3′" },
            { time: "12-30", name: "生理学", number: "20′" },
            { time: "12-30", name: "生物化学", number: "35′" },
            { time: "12-30", name: "病理学", number: "53′" },
            { time: "12-29", name: "医学微生物学", number: "53′" },
            { time: "12-29", name: "医学免疫学", number: "53′" },
            { time: "12-29", name: "药理学", number: "53′" },
            { time: "12-29", name: "学免疫学", number: "53′" },
          ],
        },
        {
          contentTitle: "医保政策",
          profession: [
            { time: "12-30", name: "解剖学", number: "3′" },
            { time: "12-30", name: "生理学", number: "20′" },
            { time: "12-30", name: "生物化学", number: "35′" },
            { time: "12-30", name: "病理学", number: "53′" },
            { time: "12-29", name: "医学微生物学", number: "53′" },
            { time: "12-29", name: "医学免疫学", number: "53′" },
            { time: "12-29", name: "药理学", number: "53′" },
            { time: "12-29", name: "学免疫学", number: "53′" },
            { time: "12-28", name: "药理学", number: "53′" },
            { time: "12-28", name: "药理学", number: "53′" },
            { time: "12-28", name: "药理学", number: "53′" },
          ],
        },
      ],
      markList: [],
      timeTotal: 0,
      loading: false,
    };
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },
  async activated() {
    this.timeTotal = 0;
    this.loading = true;
    await this.getStudyMark();
    this.loading = false;
  },
  methods: {
    async getStudyMark() {
      let res = await this.$newsApi.getStudyMark();
      if (res.code == 200) {
        this.markList = res.data.lists;
        this.timeTotal = 0;
        for (let i in this.markList) {
          let num = 0;
          let total = 0;
          let length = 0;
          let catalog = this.markList[i].catalog;
          for (let j in catalog) {
            catalog[j].create_time = this.$moment(catalog[j].create_time)
              .format()
              .substring(5, 10);
            num += catalog[j].time;
            total += catalog[j].status;
            length = catalog.length;
            catalog[j].hour = this.s_to_hs(catalog[j].time).split(":");
          }
          this.markList[i].timeTotal = num;
          this.markList[i].precent =
            total == 0 ? 0 : Math.round((total / length) * 100);
          this.timeTotal += this.markList[i].timeTotal;
        }
        this.timeTotal = String(this.s_to_hs(this.timeTotal)).split(":");
      } else {
        this.$message.error(res.message);
      }
    },
    s_to_hs(s) {
      //计算分钟
      //算法：将秒数除以60，然后下舍入，既得到分钟数
      var h;
      h = Math.floor(s / 60);
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s = s % 60;
      //将变量转换为字符串
      h += "";
      s += "";
      //如果只有一位数，前面增加一个0
      h = h.length == 1 ? "0" + h : h;
      s = s.length == 1 ? "0" + s : s;
      return h + ":" + s;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.learningTrack {
  ::v-deep .el-progress__text {
    top: 40%;
    font-size: 18px !important;
    color: #333;
  }
  background-color: white;
  > header {
    box-sizing: border-box;
    padding: 15px 30px;
    font-size: 24px;
    color: #333333;
    border-bottom: 1px solid #d9d9d9;
  }
  > .content {
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: column;
    > .learningTrack-one {
      display: flex;
      flex-direction: row;
      align-items: center;
      .learningTrackLeft {
        display: flex;
        align-self: baseline;
        min-width: 280px;
      }
      .learningTrack-avatar {
        width: 84px;
        height: 84px;
        object-fit: cover;
        border-radius: 50%;
      }
      .learningTrack-right {
        display: flex;
        flex-direction: column;
        color: #333333;
        font-size: 16px;
        margin: 0 30px 0 20px;
        > .learningTrack-time {
          font-size: 24px;
          margin-top: 16px;
          flex: 1;
        }
      }
      .progressBarBox {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1;
        .progressBar {
          position: relative;
          margin-right: 30px;

          .progressBar-profession {
            position: absolute;
            font-size: 8px;
            top: 46px;
            left: 16px;
            // left: 50%;
            // margin-left: -41px;
            width: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
          }
        }
      }
    }
    > .learningTrack-course {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: space-between;
      > .course-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #333333;
        font-size: 18px;
        // width: 478px;
        overflow: hidden;
        margin-top: 50px;
        cursor: pointer;
        > .content-title {
          font-weight: 600;
          margin-bottom: 13px;
        }
        > .courseDetails {
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          font-size: 16px;
          overflow: hidden;
          white-space: nowrap;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
          > .border {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            background: #ffffff;
            border: 2px solid #8f8f8f;
            border-radius: 50%;
            margin-right: 14px;
            > .border-round {
              width: 8px;
              height: 8px;
              background: #8f8f8f;
              border-radius: 50%;
            }
          }
          .border1 {
            border: 2px solid $--themeColor;
            .border-round1 {
              background: $--themeColor;
            }
          }
          > .point {
            flex: 1;
            overflow: hidden;
            margin-left: 7px;
            margin-right: 2px;
          }
          > .courseDetails-time {
            color: #8f8f8f;
            margin-right: 14px;
          }
        }
      }
    }
  }
}
</style>
