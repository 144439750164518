<template>
  <div class="diagnosisAndTreatmentLog">
    <header>
      <span>诊疗日志</span>
      <div style="margin-left: auto; display: flex">
        <el-input
          autocomplete="off"
          style="width: 180px; margin-right: 20px"
          clearable
          v-model="search"
          @change="anew"
          placeholder="搜索"
        ></el-input>
        <el-button round @click="showAdd = true">新增</el-button>
      </div>
    </header>

    <!-- 选择器 -->
    <div class="filter">
      <!-- 日期选择器 -->
      <el-date-picker
        v-model="month"
        :editable="false"
        :clearable="false"
        type="month"
        placeholder="选择日期"
        @change="getDate"
        :picker-options="pickerOptions"
        :append-to-body="false"
      >
      </el-date-picker>
      <i class="el-icon-caret-bottom"></i>

      <!-- 类型选择器 -->
      <el-select
        @change="changeType()"
        v-model="journalValue"
        placeholder="请选择"
        :popper-append-to-body="false"
      >
        <el-option
          v-for="item in journalOption"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <div class="content" v-loading="loading">
      <div v-if="!loading && !operationList.length" class="null-box">
        <img
          :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
          alt=""
          srcset=""
        />
        <div class="title">您还没有添加诊疗日志哦</div>
      </div>

      <div
        class="list"
        v-for="(item, i) in operationList"
        :key="item.id"
        @click="check(item)"
      >
        <img
          v-if="item.patient_sex == 1"
          :src="$http.baseURL + '/static/images/web/1.png'"
          alt=""
        />
        <img
          v-else
          :src="$http.baseURL + '/static/images/web/e87e05955797282f.png'"
          alt=""
        />
        <div class="user">
          <span class="name">{{ item.patient_name }}</span>
          <span class="remark">{{ item.name }}</span>
        </div>

        <div class="date">
          <div class="info">
            <p>{{ item.type == 1 ? "诊疗" : "手术" }}</p>
            <p>{{ item.create_time | timeFormat("yyyy-mm-dd") }}</p>
          </div>

          <div class="edit_info">
            <i
              class="el-icon-edit"
              @click.stop="setUser(item, i)"
              title="修改"
            ></i>
            <i
              class="el-icon-delete"
              @click.stop="operationdelete(item, i)"
              title="删除"
            ></i>
          </div>
        </div>
      </div>
      <div class="paging">
        <el-pagination
          hide-on-single-page
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="per_page"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      width="800px"
      top="10vh"
      custom-class="showDetail"
      :visible.sync="showDetail"
    >
      <span class="title">患者详情</span>
      <div class="info">
        <div class="user">
          <img
            v-if="formDeils.patient_sex == 1"
            class="avatar"
            :src="$http.baseURL + '/static/images/web/1.png'"
            alt=""
          />
          <img
            v-else
            class="avatar"
            :src="$http.baseURL + '/static/images/web/e87e05955797282f.png'"
            alt=""
          />
          <span class="name">{{ formDeils.patient_name }}</span>
          <span class="remark">{{ formDeils.name }}</span>
          <span class="remark">{{
            formDeils.type == 1 ? "诊疗" : "手术"
          }}</span>
        </div>
        <div class="content">
          <span class="key">信息：</span>
          <span
            >{{ formDeils.patient_sex == 1 ? "男" : "女" }}
            {{ formDeils.patient_age }}岁</span
          >
          <span class="key">电话：</span>
          <span>{{ formDeils.patient_photo }}</span>
          <span class="key">城市：</span>
          <span>{{ formDeils.province_name }} {{ formDeils.city_name }}</span>
        </div>
      </div>
      <div class="list">
        <pre>{{ formDeils.drug }}</pre>
      </div>
      <div class="list">
        <pre>{{ formDeils.content }}</pre>
      </div>
      <div class="img">
        <span class="key">病例：</span>
        <div class="imgages-box">
          <el-image
            class="image"
            v-for="(item, i) in formDeils.images"
            :key="i"
            :z-index="3000"
            :src="item"
            :preview-src-list="formDeils.images"
          >
          </el-image>
          <!-- preview-src-list图片放大的列表 -->
        </div>
      </div>
    </el-dialog>
    <el-dialog
      width="800px"
      top="10vh"
      custom-class="showAdd"
      :before-close="logClose"
      :visible.sync="showAdd"
    >
      <span class="title">新增</span>
      <field
        label="工作类型"
        type="radio"
        v-model="addForm.type"
        :options="[
          { value: 1, label: '诊疗' },
          { value: 2, label: '手术' },
        ]"
      ></field>
      <!-- <field label="疾病" type="select" v-model="addForm.disease" placeholder="请选择" :options="[ { value: 1, label: '疾病1' }, { value: 2, label: '疾病2' }, ]" ></field> -->
      <field
        label="患者"
        type="text"
        v-model="addForm.patient_name"
        placeholder="请输入"
      ></field>
      <field
        label="疾病"
        type="text"
        v-model="addForm.name"
        placeholder="请输入"
      ></field>
      <field
        label="手机"
        type="text"
        v-model="addForm.patient_photo"
        placeholder="请输入"
      ></field>
      <field
        label="性别"
        type="select"
        v-model="addForm.patient_sex"
        placeholder="请选择"
        :options="[
          { value: 1, label: '男' },
          { value: 2, label: '女' },
        ]"
      ></field>
      <field label="出生日期">
        <el-date-picker
          :picker-options="{
            disabledDate: (time) => time.getTime() > Date.now(),
          }"
          v-model="addForm.patient_date"
          value-format="yyyy-MM-dd"
          @change="chonTime"
          :editable="false"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </field>
      <field
        label="年龄"
        type="text"
        v-model="addForm.age"
        read
        placeholder="请输入"
      ></field>
      <field label="城市">
        <!-- <el-date-picker v-model="addForm.patient_city_id" :editable="false" type="date" placeholder="请选择" > </el-date-picker> -->
        <el-cascader
          filterable
          clearable
          v-model="addForm.patient_city_id"
          :options="cityList"
          :props="{ expandTrigger: 'hover', value: 'id', label: 'name' }"
          @change="handleChange"
          :popper-append-to-body="false"
        ></el-cascader>
      </field>
      <el-input
        autocomplete="off"
        type="textarea"
        :rows="4"
        placeholder="用药信息…"
        v-model="addForm.drug"
      >
      </el-input>
      <el-input
        type="textarea"
        :rows="4"
        placeholder="更多信息…"
        v-model="addForm.content"
      >
      </el-input>

      <p style="color: #8f8f8f; padding: 40px 0">
        您提交诊疗日志信息属于不公开信息只有本人可查看、编辑、删除。请您放心使用。
      </p>
      <div class="img">
        <div class="title-name">病例：</div>
        <uploadingImg
          :imgList="addForm.images"
          right="25px"
          rightNum="5"
          width="110px"
        ></uploadingImg>
      </div>
      <el-button
        class="confirm"
        :loading="isBtn"
        @click="postOperationAdd"
        round
        >确定</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
import uploadingImg from "@components/common/uploadingImg";
import city from "@/utils/city.js";
export default {
  components: {
    uploadingImg,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      month: new Date(),
      showDetail: false,
      showAdd: false,
      isBtn: false,
      loading: false,
      cityList: [],
      operationList: [],
      total: 0,
      page: 1,
      per_page: 10,
      search: "", //搜索内容
      formDeils: {},
      addForm: {
        type: "", //类型
        name: null, //疾病
        patient_name: null, //患者
        patient_photo: null, //手机
        patient_sex: 1, //性别
        patient_date: null, //出生日期
        age: 0, //年龄
        patient_city_id: 0, //城市
        patient_province_id: 0, //省份
        drug: null, //用药信息
        content: null, //更多信息
        images: [], //病例
      },
      journalOption: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 1,
          label: "诊疗",
        },
        {
          value: 2,
          label: "手术",
        },
      ],
      journalValue: "",
    };
  },
  created() {
    this.getOperationList();
    this.cityList = city.data.map((v) => {
      v.children = v.datas.map((v2) => {
        delete v2.datas;
        return v2;
      });
      return v;
    });
  },
  methods: {
    setUser(item) {
      let obj = Object.assign({}, item);
      this.showAdd = true;
      obj.age = obj.patient_age;
      obj.patient_city_id = [obj.patient_province_id, obj.patient_city_id];
      this.addForm = obj;
    },
    async operationdelete(item, i) {
      this.$msgbox({
        title: "消息",
        message: "您确定要删除该诊疗日志吗?",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: async (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            let { code, message } = await this.$userApi.operationdelete({
              id: item.id,
            });
            done();
            instance.confirmButtonLoading = false;
            if (code == 200) {
              this.xin.message("删除成功!");
              this.operationList.splice(i, 1);
            } else {
              this.$message.error(message);
            }
          } else {
            done();
          }
        },
      }).then((action) => {});
    },
    async anew() {
      //搜索
      this.page = 1;
      this.getOperationList();
    },
    async check(item) {
      //查看详情
      this.$loading.show();
      let { data, code, message } = await this.$userApi.getOperationInfo({
        id: item.id,
      });
      if (code == 200) {
        data.images = JSON.parse(data.images || "[]");
        this.formDeils = data;
      } else {
        this.$message.error(message);
      }
      this.$loading.hide();
      this.showDetail = true;
    },
    async handleCurrentChange(val) {
      this.page = val;
      this.getOperationList();
    },

    // 诊疗和手术切换筛选
    changeType() {
      this.getOperationList();
    },

    async getOperationList() {
      //获取日志
      let { month, page } = this;
      this.loading = true;
      let { data, code, message } = await this.$userApi.getOperationList({
        month: month.getMonth() + 1,
        year: month.getFullYear(),
        page,
        type: this.journalValue,
        name: this.search,
      });
      this.loading = false;
      if (code == 200) {
        data.data.forEach((v) => {
          v.images = JSON.parse(v.images || []);
        });
        this.operationList = data.data;
        this.total = data.total;
        this.per_page = data.per_page;
      } else {
        this.$message.error(message);
      }
    },
    chonTime(time) {
      //计算年龄
      this.addForm.age = this.xin.jsGetAge(time);
    },
    handleChange(e) {
      e;
    },
    logClose(doem) {
      //
      if (this.isBtn) return;
      this.addForm = this.$options.data().addForm;
      if (doem) doem();
    },
    validate() {
      var myreg = /^1[3-9]\d{9}$/;
      let {
        name,
        patient_name,
        patient_photo,
        patient_sex,
        patient_date,
        age,
        patient_city_id,
        drug,
        content,
      } = this.addForm;
      if (!name) {
        this.xin.message("请输入疾病", "err");
      } else if (!patient_name) {
        this.xin.message("请输入患者", "err");
      } else if (!patient_photo) {
        this.xin.message("请输入手机号", "err");
      } else if (!myreg.test(patient_photo)) {
        this.xin.message("手机号格式不正确", "err");
      } else if (!patient_sex) {
        this.xin.message("请选择性别", "err");
      } else if (!patient_date) {
        this.xin.message("请选择出生日期", "err");
      } else if (!patient_city_id || !patient_city_id.length) {
        this.xin.message("请选择城市", "err");
      } else if (!drug) {
        this.xin.message("请输入用药信息", "err");
      } else if (!content) {
        this.xin.message("请输入更多信息", "err");
      } else {
        return true;
      }
      return false;
    },
    async postOperationAdd() {
      //添加
      if (!this.validate()) return;
      let form = Object.assign({}, this.addForm);
      form.images = JSON.stringify(form.images);
      form.patient_province_id = form.patient_city_id[0]; //省份
      form.patient_city_id = form.patient_city_id[1]; //城市
      // return
      this.isBtn = true;
      var { code, message } = await this.$userApi.postOperationAdd(form);
      this.isBtn = false;
      if (code == 200) {
        this.showAdd = false;
        this.addForm = this.$options.data().addForm;
        if (form.id) {
          this.xin.message("修改成功!");
        } else this.xin.message("新增日志成功!");
        this.getOperationList();
      } else {
        this.$message.error(message);
      }
    },
    getDate() {
      this.getOperationList();
      this.page = 1;
    },
    handleAvatarSuccess(res, file) {
      this.userInfo.avatar = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.diagnosisAndTreatmentLog {
  background-color: white;
  > header {
    box-sizing: border-box;
    padding: 15px 30px;
    font-size: 24px;
    color: #333333;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    .el-button {
      // margin-left: auto;
      background-color: $--themeColor;
      color: white;
      border: none;
      margin-left: auto;
      border-radius: 5px;
      font-size: 20px;
      height: 40px;
    }
  }
  .filter {
    display: flex;
    align-items: center;
    margin-top: 30px;
    // 日期选择器样式
    ::v-deep .el-date-editor {
      z-index: 1;
      height: 30px;
      box-sizing: border-box;
      border-radius: 30px;
      border: 1px solid #8f8f8f;
      background: none;
      width: max-content;
      margin-left: 14px;
      input {
        // padding-left:  7px;
        cursor: pointer;
        width: 120px;
        border: none;
        margin: 5px 6px;
        height: 20px;
        line-height: 20px;
      }
      // 前面icon样式
      .el-input__prefix {
        display: none;
      }
    }
    ::v-deep .el-input .el-input__icon {
      display: flex;
      align-items: center;
    }
    > .el-icon-caret-bottom {
      left: -24px;
      font-size: 14px;
      color: #8f8f8f;
      z-index: 9;
    }
  }
  > .content {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    > .list {
      box-sizing: border-box;
      padding: 30px;
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      align-items: center;
      &:last-child {
        border: none;
      }
      > img {
        width: 70px;
        height: 70px;
        border-radius: 70px;
        object-fit: cover;
        margin-right: 20px;
      }
      > .user {
        display: flex;
        flex-direction: column;
        line-height: 30px;
        > .name {
          font-size: 24px;
          color: #333333;
        }
        > .remark {
          font-size: 16px;
          color: #8f8f8f;
        }
      }
      > .date {
        margin-left: auto;
        font-size: 16px;
        color: #333333;
        height: 50px;
        display: flex;
        align-items: center;
        // flex-direction: column;
        // justify-content: space-between;

        > .info {
          text-align: right;
          font-size: 16px;
          color: #8f8f8f;
          line-height: 30px;
          margin-right: 20px;

          > p:last-child {
            color: #666;
          }
        }

        > .edit_info {
          > i {
            font-size: 22px;
            font-weight: 500;
            color: #4d4d4d;
            cursor: pointer;
          }
          > i:first-child {
            margin-right: 15px;
          }
        }
      }
    }
  }
  ::v-deep {
    input {
      outline: none;
    }
    .showDetail {
      border-radius: 20px;
      .el-dialog__body {
        background-color: #f2f2f2;
        box-sizing: border-box;
        padding: 40px 30px;
        > .title {
          font-size: 24px;
          color: #333333;
        }
        > .info {
          background-color: white;
          margin-top: 25px;
          box-sizing: border-box;
          padding: 20px;
          display: flex;
          flex-direction: column;
          > .user {
            display: flex;
            align-items: center;
            > .avatar {
              width: 70px;
              height: 70px;
              border-radius: 70px;
              object-fit: cover;
            }
            > .name {
              font-size: 24px;
              color: #333333;
              margin-left: 20px;
            }
            > .remark {
              font-size: 16px;
              color: #8f8f8f;
              margin-left: 14px;
            }
          }
          > .content {
            margin-top: 20px;
            display: flex;
            align-items: center;
            > span {
              font-size: 16px;
              color: #333333;
              &.key {
                color: #8f8f8f;
                margin-left: 60px;
                &:first-child {
                  margin-left: 0;
                }
              }
            }
          }
        }
        > .list {
          background-color: white;
          margin-top: 20px;
          box-sizing: border-box;
          padding: 20px;
          font-size: 16px;
          line-height: 20px;
          color: #333333;
          pre {
            white-space: pre-wrap;
          }
        }
        > .img {
          box-sizing: border-box;
          padding: 20px;
          margin-top: 20px;
          background-color: white;
          display: flex;
          > div :nth-child(1) {
            font-size: 17px;
            color: #8f8f8f;
            width: 100px;
          }
          .imgages-box {
            flex: 1;
            .image {
              margin-top: 10px;
              width: 110px;
              height: 110px;
              margin-right: 25px;
              &:nth-child(5n) {
                margin-right: 0px;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 12px;
                display: block;
              }
            }
          }
        }
      }
    }
    .showAdd {
      border-radius: 20px;
      .el-input__inner {
        text-align: right;
      }
      .el-dialog__body {
        box-sizing: border-box;
        padding: 40px 30px;
        > .title {
          font-size: 24px;
          color: #333333;
        }
        .el-date-editor {
          background-color: white;
          display: flex;
          width: auto;
          cursor: pointer;
          .el-input__inner {
            padding: 0;
            width: max-content;
            text-align: right;
            right: 0;
            font-size: 16px;
            cursor: pointer;
            color: #333333;
            &::placeholder {
              color: #8f8f8f;
            }
          }
          .el-input__prefix,
          .el-input__suffix {
            position: absolute;
            display: none;
          }
        }
        .icon_2 {
          margin-left: 6px;
          transition: 0.3s;
          font-size: 18px;
          color: #8f8f8f;
        }
        .el-textarea {
          margin-top: 20px;
          border-radius: 10px;
          overflow: hidden;
          > textarea {
            background-color: #f2f2f2;
            border-radius: 10px;
            border: none;
          }
        }
        > .img {
          display: flex;
          margin-top: 20px;
          .title-name {
            font-size: 17px;
            color: #8f8f8f;
            width: 56px;
          }
          > div:nth-child(2) {
            flex: 1;
          }

          .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
          }
          .avatar-uploader .el-upload:hover {
            border-color: #409eff;
          }
          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 110px;
            height: 110px;
            line-height: 110px;
            text-align: center;
          }
          .avatar {
            width: 110px;
            height: 110px;
            display: block;
          }
        }
        > .confirm {
          margin: 40px auto 0;
          border: none;
          width: 360px;
          height: 44px;
          border-radius: 44px;
          // background-color: #dbfbf8;
          background: $--themeColor;
          color: white;
          font-size: 18px;
        }
      }
    }
    .filter .el-select .el-input--suffix .el-input__inner {
      height: 30px;
      line-height: 30px;
      border: 1px solid #8f8f8f;
      width: 150px;
      border-radius: 200px;
    }
  }
}
</style>
