<template>
  <div class="onlineResearch">
    <header>
      <span>在线调研</span>
      <el-button round icon="iconfont iconjiatianjia-2">发布</el-button>
    </header>
    <div class="content">
      <div class="list" v-for="(item, index) in 4" :key="index">
        <div class="img">
          <img class="banner2" src="@image/zaixiandiaoyan.png" />
          <div v-if="false" class="mark">
            <span>在线调研</span>
          </div>
        </div>
        <div class="left-onLine-right">
          <span>非小细胞肺癌诊断治疗市场调研治疗市场治疗市场</span>
          <span class="subTitle"
            >非小细胞肺癌诊断治疗市场调研治疗市场治疗市场…</span
          >
          <div class="right-button">
            <span class="blue">30分钟</span>
            <span class="blue">¥100</span>
            <span class="blue">我要推荐</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.onlineResearch {
  background-color: white;
  > header {
    box-sizing: border-box;
    padding: 15px 30px;
    font-size: 24px;
    color: #333333;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    > .el-button {
      margin-left: auto;
      height: 40px;
      border-radius: 40px;
      background-color: $--themeColor;
      border: none;
      color: white;
      border-radius: 6px;
      ::v-deep {
        .iconjiatianjia-2 {
          font-size: 15px;
          color: white;
        }
      }
    }
  }
  > .content {
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    > .list {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow: hidden;
      padding: 30px 0;
      box-sizing: border-box;
      border-bottom: 1px solid #f2f2f2;
      &:nth-child(3n - 1) {
        > .img {
          > .mark {
            background: #e60012;
          }
        }
        > .left-onLine-right {
          > .right-button {
            > .blue {
              &:nth-child(2) {
                display: none;
                width: 96px;
                margin-left: 30px;
              }
            }
          }
        }
      }
      &:nth-child(3n) {
        > .img {
          > .mark {
            background: $--themeColor;
          }
        }
        > .left-onLine-right {
          > .right-button {
            > .blue {
              &:nth-child(2) {
                display: none;
                width: 96px;
                margin-left: 30px;
              }
            }
          }
        }
      }
      > .img {
        width: 150px;
        height: 150px;
        overflow: hidden;
        > .banner2 {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        > .mark {
          background: #f6e06a;
          width: 120px;
          height: 24px;
          transform: rotate(307deg); //旋转
          position: absolute;
          text-align: center;
          left: -35px;
          top: 25px;
          line-height: 24px;
          color: #ffffff;
          font-size: 12px;
        }
      }

      > .left-onLine-right {
        width: calc(100% - 150px - 30px);
        // flex-direction: row-reverse;
        display: flex;
        flex-direction: column;
        color: #333333;
        font-size: 24px;
        > .subTitle {
          font-size: 18px;
          color: #8f8f8f;
          margin-top: 10px;
        }
        > .right-button {
          display: flex;
          flex-direction: row;
          font-size: 18px;
          color: #ffffff;
          margin-top: auto;
          > .blue {
            &:nth-child(2) {
              width: 96px;
              margin-left: 30px;
            }
            &:last-child {
              width: 132px;
              background: #f6e06a;
              color: #333333;
              margin-left: auto;
            }
            width: 110px;
            height: 44px;
            background: linear-gradient(90deg, $--themeColor, #46cbca);
            border-radius: 5px;
            text-align: center;
            line-height: 44px;
          }
        }
      }
    }
  }
}
</style>
