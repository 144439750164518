<template>
  <!-- 提现明细 -->
  <div class="withdrawalDetails">
    <h4 @click="backProfitList"><i class="el-icon-arrow-left"></i> 提现明细</h4>
    <!-- 提现明细列表 -->
    <ul class="content_warp" v-if="list.length">
      <li v-for="item in list" :key="item.id">
        <div class="left">
          <p>{{ item.type ? "创作收益" : "" }}</p>
          <p>{{ item.source_name }}</p>
        </div>
        <div class="right">
          <p>+{{ item.amount_change }}</p>
          <p>{{ item.create_time }}</p>
        </div>
      </li>
    </ul>

    <!-- 暂无数据 -->
    <div class="null-box" v-else>
      <img
        :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
        alt=""
      />
      <div class="title">暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "withdrawalDetails",
  data() {
    return {
      list: [],
      detail_id: localStorage.getItem("detail_id"),
    };
  },

  created() {
    this.wyProfitList();
  },

  methods: {
    // 网医收益提现记录
    async wyProfitList() {
      let token = localStorage.getItem("token");
      let res = await this.$userApi.wyProfitList({
        token,
        detail_id: this.detail_id,
      });
      if (res.code == 200) {
        this.list = res.data;
      } else {
        this.$message.error(res.message);
      }
    },

    // 返回
    backProfitList() {
      this.$emit("backProfitList");
    },
  },
};
</script>

<style lang="scss" scoped>
// /deep/.el-month-table td.now_profit {
//   background: red;
// }

.withdrawalDetails {
  box-sizing: border-box;
  padding: 10px 27px 20px;
  min-height: 445px;

  h4 {
    font-size: 20px;
    cursor: pointer;
    padding: 20px 0;

    i {
      font-weight: 700;
    }
  }

  > .content_warp {
    padding: 0 10px;
    > li {
      color: #333;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #b1b1b1;
      margin-bottom: 10px;

      > .left {
        flex: 1;

        > p:first-child {
          color: #919191;
          font-size: 14px;
          margin-bottom: 5px;
        }

        > p:last-child {
          font-size: 16px;
          font-weight: 700;
        }
      }

      > .right {
        text-align: right;

        > p:first-child {
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 5px;
        }

        > p:last-child {
          color: #919191;
          font-size: 12px;
        }
      }
    }
  }
}
</style>>
