<template>
  <div>
    <!-- 收益提现 -->
    <div class="surplus" v-if="withdrawalList.length">
      <header>
        <i
          @click="goBack"
          style="margin-right: 10px; cursor: pointer"
          class="el-icon-arrow-left"
        ></i>
        收益提现
      </header>

      <!-- 提现 -->
      <section v-show="!withdrawalListShow">
        <div class="item1">
          <div class="left-title">提现方式</div>
          <div class="right-text" @click="paymentVisible = true">
            <div v-if="!checkedInfo">请选择</div>

            <div v-if="checkedInfo">
              {{ checkedInfo.type == 2 ? "支付宝" : "微信" }}
              <p style="margin-top: 10px">{{ checkedInfo.card }}</p>
            </div>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>

        <div class="item2">
          <div class="extract">
            提取现金
            <div class="cash" @click="goWithdrawalList">
              <span>¥</span>
              <el-input v-model="cash" readonly></el-input>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>

        <div class="desc" v-show="cashableShow">
          <!-- 我的可提现积分<span class="cashable">{{ cashable }}</span>
          <span @click="allcash" class="allcash">全部提现</span> -->
        </div>
        <div class="taxation" v-show="taxationShow">
          收取个税{{ tax.toFixed(2) }}元
        </div>
        <div class="err_msg" v-show="showErrMsg">
          {{ err_msg }}
        </div>

        <div class="verification_code">
          <span @click="getVerificationCode">
            {{ get_verification_code }}
          </span>
          <el-input
            readonly
            onfocus="this.removeAttribute('readonly');"
            v-model="verification_code"
            placeholder="验证码"
          ></el-input>
        </div>

        <div class="btn">
          <el-button
            @click="withdrawal"
            :disabled="showErrMsg ? true : false"
            round
            type="primary"
            >提现</el-button
          >
        </div>

        <div class="agree">
          <el-radio label="1" v-model="agree"></el-radio>
          <span @click="withholdVisible = true">阅读并同意扣缴申报</span>
        </div>
      </section>

      <!-- 收益提现列表 -->
      <section v-show="withdrawalListShow">
        <ul class="content_warp">
          <li v-for="item in withdrawalList" :key="item.id">
            <el-checkbox
              v-model="checked"
              :key="item.id"
              :label="item.id"
              @change="handleCheckedChange"
            ></el-checkbox>
            <div class="left">
              <p>
                {{ item.status == 1 ? "" : "创作收益" }}-{{ item.type_name }}
              </p>
              <p>{{ item.title }}</p>
            </div>

            <div class="right">
              <p>+{{ item.amount_change }}</p>
              <p>{{ item.create_time }}</p>
            </div>
          </li>
        </ul>

        <div class="bottom">
          <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
            >全选 {{ total }}¥</el-checkbox
          >
          <el-button @click="backsSurplus" round type="primary">确定</el-button>
        </div>
      </section>

      <!-- 支付方式对话框 -->
      <el-dialog
        title="选择转入方式"
        custom-class="paymentType"
        :visible.sync="paymentVisible"
        width="30%"
      >
        <div class="close" @click="paymentVisible = false">
          <img src="@image/closebill.png" alt="" />
        </div>

        <h4>选择转入方式</h4>

        <div
          @click="selectPayment(item.type, item.id)"
          v-for="item in popupType"
          :key="item.type"
          class="mode"
        >
          <div class="payment">
            <img :src="item.imgSrc" alt="" />
            {{ item.name }}
          </div>
          <div class="operation">
            <div
              v-if="!item.id"
              @click="$router.push('/setting')"
              class="toBinding"
            >
              去绑定
            </div>
            <div v-else class="success"></div>
          </div>
        </div>

        <div class="footer">
          <el-button @click="paymentVisible = false" round type="primary"
            >确认提现</el-button
          >
        </div>
      </el-dialog>

      <!-- 扣缴申报对话框 -->
      <el-dialog
        title="扣缴申报"
        custom-class="withhold"
        :visible.sync="withholdVisible"
        width="35%"
      >
        <div class="close" @click="withholdVisible = false">
          <img src="@image/closebill.png" alt="" />
        </div>
        <h4>扣缴申报</h4>
        <div class="word">
          <p>
            为规范个人所得税扣缴申报行为，维护纳税人和扣缴义务人合法权益，根据《中华人民共和国个人所得税法》及其实施条例、《中华人民共和国税收征收管理法》及其实施细则等法律法规的规定，制定本办法。
            扣缴义务人，是指向个人支付所得的单位或者个人。扣缴义务人应当依法办理全员全额扣缴申报。全员全额扣缴申报，是指扣缴义务人应当在代扣税款的次月十五日内，向主管税务机关报送其支付所得的所有个人的有关信息、支付所得数额、扣除事项和数额、扣缴税款的具体数额和总额以及其他相关涉税信息资料。
            即健康通将依据《个人所得税扣缴申报管理办法》执行，健康通即包含网医平台，医师所得收入是实际到手金额，所涉及的个税由健康通申报缴纳。
            具体可见《个人所得税扣缴申报管理办法（试行）》的公告
            <a
              target="_blank"
              href="http://www.chinatax.gov.cn/n810341/n810755/c3960540/content.html"
              >http://www.chinatax.gov.cn/n810341/n810755/c3960540/content.html</a
            >
          </p>
        </div>
      </el-dialog>

      <!-- 提现成功对话框 -->
      <el-dialog
        title="提现成功"
        custom-class="successWithdrawal"
        :visible.sync="successVisible"
        width="30%"
      >
        <div
          class="close"
          @click="
            successVisible = false;
            $router.go(0);
          "
        >
          <img src="@image/closebill.png" alt="" />
        </div>

        <div class="word">
          <img src="@image/chengSuccess.png" alt="" />
          <p>提现申请成功，等待处理</p>
          <p style="color: #717171; font-size: 12px; font-weight: 400">
            预计3个工作日内到账
          </p>
        </div>

        <el-button @click="successWithdrawal" type="primary" round>
          完成
        </el-button>
      </el-dialog>
    </div>

    <!-- 暂无数据 -->
    <div class="null-box" v-else>
      <header>
        <i
          @click="goBack"
          style="margin-right: 10px; cursor: pointer"
          class="el-icon-arrow-left"
        ></i>
        收益提现
      </header>
      <img
        :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
        alt=""
      />
      <div class="title">暂无提现数据</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "surplus",
  data() {
    return {
      agree: "", // 同意扣缴申报
      cash: "", // 提取现金
      canCode: true,
      // cashable: 10000,
      cashableShow: true,
      checkedInfo: "",
      checkAll: false,
      checked: [],
      checkedOption: [],
      withdrawalListShow: false,
      withdrawalList: [],
      paymentVisible: false,
      popupType: [
        {
          imgSrc: require("@image/weixin.png"),
          name: "微信",
          is_show: 1,
          type: 3,
        },
        {
          imgSrc: require("@image/zhifubao.png"),
          name: "支付宝",
          is_show: 1,
          type: 2,
        },
      ],
      showErrMsg: false,
      taxationShow: false,
      tax: 0,
      total: 0,
      err_msg: "",
      get_verification_code: "获取手机验证码",
      verification_code: "", // 验证码
      successVisible: false, // 提现成功对话框
      withholdVisible: false, // 扣缴申报对话框
      project: "",
    };
  },

  watch: {
    cash(val) {
      // 错误信息提示
      if (val) {
        if (val < 50) {
          this.err_msg = "输入的金额小于50元不可提现";
          this.showErrMsg = true;
          this.taxationShow = false;
          this.cashableShow = false;
          return;
        }
        // if (val > this.cashable) {
        //   this.err_msg = "输入的金额超过可提现金额";
        //   this.showErrMsg = true;
        //   this.taxationShow = false;
        //   this.cashableShow = false;
        //   return;
        // }
        this.taxationShow = true;
        this.cashableShow = false;
        this.showErrMsg = false;
        this.stageFee();
      } else {
        this.cashableShow = true;
        this.taxationShow = false;
        this.showErrMsg = false;
      }
    },
  },

  created() {
    this.wyRewardList();
  },

  mounted() {
    this.getListBank();
  },

  methods: {
    //  返回
    goBack() {
      this.$emit("goBack");
    },

    // 提现成功
    successWithdrawal() {
      this.successVisible = false;
      setTimeout(() => {
        this.$emit("goBack");
      }, 800);
    },

    // 可提现列表
    async wyRewardList() {
      let res = await this.$userApi.wyRewardList({
        token: this.token,
      });
      if (res.code == 200) {
        this.withdrawalList = res.data.list;
        this.checkedOption = this.withdrawalList.map((item) => {
          return item.id;
        });
        this.total = res.data.total;
      } else {
        this.$message.error(res.message);
      }
    },

    // 收益提现列表
    goWithdrawalList() {
      this.withdrawalListShow = true;
      //   this.checked = [];
      //   this.checkAll = false;
    },

    // 返回提现
    backsSurplus() {
      this.withdrawalListShow = false;
      // 提现金额
      let checkedArr = [];
      this.checked.forEach((item) => {
        let checkedItem = this.withdrawalList.filter((item1) => {
          return item1.id == item;
        });
        checkedArr = checkedArr.concat(checkedItem);
      });
      this.cash = checkedArr.reduce((prev, cur) => {
        return prev + Number(cur.amount_change);
      }, 0);
      this.cash = this.cash.toFixed(2);
    },

    // 选择支付方式
    selectPayment(type, id) {
      if (id) {
        if (type == 2) {
          this.checkedInfo = this.popupType[1];
        }
        if (type == 3) {
          this.checkedInfo = this.popupType[0];
        }
      }
    },

    // 提现
    async withdrawal() {
      if (!this.checkedInfo.id) {
        this.$message.error("请选择提现方式");
        return;
      }
      if (!this.cash) {
        this.$message.error("请输入提取金额");
        return;
      }

      if (!this.verification_code) {
        this.$message.error("请输入验证码");
        return;
      }
      if (!this.agree) {
        this.$message.error("请同意代缴代扣");
        return;
      }
      let project = this.checked.join(",");
      let res = await this.$userApi.platformPayment({
        token: localStorage.getItem("token"),
        type: 4,
        price: this.cash,
        pay_account_id: this.checkedInfo.id,
        code: this.verification_code,
        project,
      });
      if (res.code == 200) {
        this.successVisible = true;
      } else {
        this.$message.error(res.message);
      }
    },

    // 全部提现
    allcash() {
      // this.cash = this.cashable;
      if (this.cash < 50) {
        this.err_msg = "输入的金额小于50元不可提现";
        this.showErrMsg = true;
        this.taxationShow = false;
        this.cashableShow = false;
        return;
      } else {
        this.taxationShow = true;
        this.cashableShow = false;
        this.showErrMsg = false;
        this.stageFee();
      }
    },

    // 获取验证码
    async getVerificationCode() {
      let token = localStorage.getItem("token");
      let time = 60;
      if (this.canCode) {
        this.canCode = false;
        let res = await this.$userApi.sendCode({
          token,
        });
        if (res.code == 200) {
          this.$message.success("短信已发送,注意查收");
          let timeId = setInterval(() => {
            time--;
            if (time < 0) {
              time = 0;
              clearInterval(timeId);
              this.canCode = true;
              this.get_verification_code = "获取手机验证码";
              return;
            }
            this.get_verification_code = time + "s后重新发送";
          }, 1000);
        } else {
          this.$message.error(res.message);
        }
      }
    },

    // 判断是否绑定成功
    async getListBank() {
      let res = await this.$userApi.getListBank();
      if (res.code == 200) {
        res.data.forEach((v, i) => {
          if (v.type == 2) {
            Object.assign(this.popupType[1], v);
          }

          if (v.type == 3) {
            Object.assign(this.popupType[0], v);
          }
        });
        this.$forceUpdate();
      } else {
        this.$message.error(res.message);
      }
    },

    // 个税
    async stageFee() {
      let res = await this.$userApi.stageFee({
        token: localStorage.getItem("token"),
        price: this.cash,
      });
      if (res.code == 200) {
        this.tax = res.data.fee;
      } else {
        this.$message.error(res.message);
      }
    },

    // 单选
    handleCheckedChange() {
      this.checkAll = this.checkedOption.length == this.checked.length;
    },

    // 全选
    handleCheckAllChange(val) {
      this.checked = val ? this.checkedOption : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.null-box {
  min-height: 430px;
  padding: 0;

  header {
    box-sizing: border-box;
    padding: 0 33px;
    display: flex;
    align-items: center;
    height: 61px;
    background-color: #dbfbf8;
    font-size: 24px;
    color: #03dbc6;
    width: 100%;
  }

  img {
    margin-top: 20px;
  }
}

// 收益提现
.surplus {
  background: #fff;
  padding-bottom: 50px;

  header {
    box-sizing: border-box;
    padding: 0 33px;
    display: flex;
    align-items: center;
    height: 61px;
    background-color: #dbfbf8;
    font-size: 24px;
    color: #03dbc6;
  }

  section {
    box-sizing: border-box;
    padding: 20px 260px;
    width: 100%;
    min-height: 400px;

    // 账单详情内容
    .item1 {
      color: #000;
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #b1b1b1;

      .left-title {
        font-weight: 400;
      }

      .right-text {
        flex: 1;
        text-align: right;
        padding-right: 30px;
        position: relative;
        cursor: pointer;

        i {
          position: absolute;
          font-weight: 600;
          color: #717171;
          font-size: 22px;
          right: 0px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .item2 {
      padding-top: 15px;
      border-bottom: 1px solid #b1b1b1;

      .extract {
        .cash {
          margin-top: 10px;
          display: flex;
          align-items: end;

          /deep/ .el-input {
            height: 70px;
          }

          span {
            font-size: 70px;
            color: #fc8f56;
          }

          /deep/ .el-input__inner {
            cursor: pointer;
            color: #333;
            border: none;
            outline: none;
            height: 100%;
            padding: 10px;
            font-size: 65px;
            box-sizing: border-box;
            cursor: pointer;
          }

          i {
            position: absolute;
            font-weight: 600;
            color: #717171;
            font-size: 22px;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    .desc {
      padding-top: 15px;
      color: #b1b1b1;
      margin-bottom: 30px;

      .allcash {
        margin-left: 20px;
        color: #4ba5ff;
        cursor: pointer;
      }
    }

    .taxation {
      padding-top: 15px;
      // color: #b1b1b1; 去掉个税计算显示
      color: #fff;
      margin-bottom: 30px;
    }

    .err_msg {
      padding: 15px 0;
      color: #ff0000;
      margin-bottom: 15px;
    }

    .verification_code {
      display: flex;
      width: 70%;
      margin: 0 auto;
      background: #4ba5ff;
      color: #fff;
      font-size: 16px;
      height: 40px;
      line-height: 40px;
      border-radius: 7px;
      position: relative;

      span {
        padding-left: 15px;
        cursor: pointer;
      }

      /deep/ .el-input {
        width: 55%;
        height: 100%;
        position: absolute;
        right: 4px;
      }

      /deep/ .el-input__inner {
        border: none;
        outline: none;
        height: 34px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .btn {
      margin-top: 50px;
      text-align: center;

      button {
        width: 180px;
        font-size: 18px;
      }
    }

    .agree {
      margin-top: 30px;
      text-align: center;
      color: #4ba5ff;
      font-size: 16px;
      cursor: pointer;

      /deep/ .el-radio {
        margin-right: 0;
      }

      /deep/ .el-radio__label {
        opacity: 0;
      }

      /deep/ .el-radio__input .el-radio__inner {
        border: 1px solid #4ba5ff;
        width: 16px;
        height: 16px;
      }

      /deep/ .el-radio__input.is-checked .el-radio__inner {
        background: #4ba5ff;
      }

      /deep/ .el-radio__inner::after {
        width: 6px;
        height: 6px;
      }
    }

    // 提现
    > .content_warp {
      > li {
        color: #333;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        border-bottom: 1px solid #b1b1b1;
        margin-bottom: 10px;

        /deep/ .el-checkbox__inner {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 10px;
          border-color: #c0c0c0;
        }

        /deep/ .el-checkbox__label {
          display: none;
        }

        /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
          background: #c0c0c0 !important;
        }

        /deep/ .el-checkbox__inner::after {
          top: 3px;
          left: 7px;
          height: 8px;
        }

        /deep/ .el-checkbox .is-checked > .el-checkbox__inner::after {
          border-color: #fff !important;
        }

        > .left {
          flex: 1;

          > p:first-child {
            color: #919191;
            font-size: 14px;
            margin-bottom: 5px;
          }

          > p:last-child {
            font-size: 16px;
            font-weight: 700;
          }
        }

        > .right {
          text-align: right;

          > p:first-child {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 5px;
          }

          > p:last-child {
            color: #919191;
            font-size: 12px;
          }
        }
      }
    }

    > .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;

      /deep/ .el-checkbox__inner {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;
        border-color: #c0c0c0;
      }

      /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
        background: #c0c0c0 !important;
      }

      /deep/ .el-checkbox__label {
        color: #333 !important;
        font-size: 16px;
        padding-left: 0;
      }

      /deep/ .el-checkbox__inner::after {
        top: 3px;
        left: 7px;
        height: 8px;
      }

      /deep/ .el-checkbox .is-checked > .el-checkbox__inner::after {
        border-color: #fff !important;
      }

      .el-button {
        width: 160px;
        text-align: center;
        font-size: 16px;
      }
    }
  }
}

// 扣缴申报对话框
/deep/.withhold {
  border-radius: 15px;
  padding: 20px 40px 80px;

  .el-dialog__body {
    position: relative;
  }

  h4 {
    padding-top: 40px;
    font-weight: 500;
    color: #333333;
    font-size: 24px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid #eaeaea;
  }

  .close {
    position: absolute;
    right: -20px;
    top: -10px;
    width: 25px;
    cursor: pointer;

    img {
      width: 100%;
      cursor: pointer;
    }
  }

  .word {
    color: #000;
    font-size: 16px;
    line-height: 26px;
    text-align: justify;

    a {
      color: #4ba5ff;
      text-decoration: none;
    }
  }
}

// 提现成功对话框
/deep/.successWithdrawal {
  border-radius: 15px;
  padding: 20px 40px 80px;

  .close {
    position: absolute;
    right: -20px;
    top: -10px;
    width: 25px;
    cursor: pointer;

    img {
      width: 100%;
      cursor: pointer;
    }
  }

  .word {
    padding-top: 50px;
    text-align: center;

    img {
      width: 160px;
      margin-bottom: 30px;
    }

    p {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 15px;
    }
  }

  button {
    padding: 0;
    width: 150px;
    margin: 30px auto;
    height: 38px;
    font-size: 20px;
    font-weight: 300;
  }
}

// 支付方式对话框
/deep/.paymentType {
  border-radius: 15px;
  padding: 20px 40px 80px;

  .close {
    position: absolute;
    right: -20px;
    top: -10px;
    width: 25px;
    cursor: pointer;

    img {
      width: 100%;
      cursor: pointer;
    }
  }

  h4 {
    padding-top: 40px;
    font-weight: 500;
    color: #333333;
    font-size: 24px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #f2f2f2;
  }

  .mode {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 0;

    .payment {
      font-size: 18px;
      img {
        width: 42px;
        vertical-align: middle;
        color: #333333;
      }
    }

    .operation {
      .toBinding {
        cursor: pointer;
        color: #03dbc6;
        border: 1px solid #03dbc6;
        width: 70px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        border-radius: 50px;
      }

      .success {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #03dbc6;
        position: relative;

        &::after {
          content: "";
          border: 2px solid #fff;
          border-left: 0;
          border-top: 0;
          height: 14px;
          left: 8px;
          position: absolute;
          top: 2px;
          transform: rotate(45deg) scaleY(1);
          width: 6px;
          transition: transform 0.15s ease-in 0.05s;
          transform-origin: center;
        }
      }
    }
  }

  .footer {
    text-align: center;
    margin-top: 150px;
  }
}
</style>