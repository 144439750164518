<template>
  <div>
    <div class="images-box">
      <div
        :style="`width:${width};margin-top:${right}};margin-right:${
          rightNum == i + 1 ? '0px' : right
        }`"
        class="imgs"
        v-for="(item, i) in imgList"
        :key="i"
      >
        <img :src="item" alt="" />
        <div class="rowp">
          <span @click="removeImg(i)" class="el-icon-delete"></span>
          <span
            @click="
              isImg = true;
              imgSrc = item;
            "
            class="el-icon-zoom-in"
          ></span>
        </div>
      </div>
      <div
        v-if="imgList.length < maxNum"
        :style="[{ width: width }, { height: width }]"
        class="add-img"
      >
        <i class="el-icon-plus"> </i>
        <input type="file" @change="uploadImg" multiple ref="imgTop" />
        配图（选填）
      </div>
    </div>
    <el-dialog :modal="false" custom-class="login" :visible.sync="isImg">
      <el-image @click="isImg = false" :src="imgSrc"></el-image>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isImg: false,
      imgSrc: "",
    };
  },
  props: {
    rightNum: {
      type: [Number, String],
      default: 4,
    },
    right: {
      type: String,
      default: "32px",
    },
    width: {
      type: String,
      default: "150px",
    },
    maxNum: {
      type: Number,
      default: 9,
    },
    imgList: {
      type: Array,
      default: [],
    },
  },
  methods: {
    removeImg(i) {
      //删除图片
      this.imgList.splice(i, 1);
    },
    async uploadImg() {
      //图片上传  8  4
      let files = [...this.$refs.imgTop.files];
      let maxNum = this.maxNum - this.imgList.length;
      // return
      if (!files.length || !maxNum) {
        return;
      } else if (files.length > maxNum) {
        files.splice(maxNum, files.length);
      }
      // return;
      let ajaxNum = 0;
      this.$loading.show();
      files.forEach(async (v, i) => {
        let formData = new FormData();
        formData.append("files", v);
        let { data, code, message } = await this.$userApi.postFilesUpload(
          formData
        );
        ajaxNum++;
        if (code == 200) {
          this.imgList.push(data);
        } else {
          this.$message.error(message);
        }
        if (ajaxNum == files.length) {
          this.$loading.hide();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

/deep/.login {
  border-radius: 0;
  .el-dialog__body {
    padding: 0 !important;
    z-index: 2021;
    &::before {
      content: "";
      display: block;
      height: 2px;
      width: 100%;
      background-color: $--themeColor;
    }
  }
}
.images-box {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  border-bottom: #f2f2f2 1px solid;
  .imgs {
    position: relative;
    width: 150px;
    height: auto;
    border-radius: 5px;
    margin-top: 22px;
    margin-right: 32px;
    // &:nth-child(4n){
    //     margin-right: 0;
    // }
    &:hover .rowp {
      opacity: 1;
      // transition: all 1s linear;
    }
    .rowp {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: #0005;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      color: #fff;
      opacity: 0;
      transition: opacity 0.3s;
      border-radius: 5px;
      span {
        padding: 5px;
        font-size: 24px;
        cursor: pointer;
      }
    }
    img {
      border-radius: 5px;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .add-img {
    margin-top: 22px;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: auto;
    color: #bbb8b8;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    input {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      opacity: 0;
      cursor: pointer;
    }
    .el-icon-plus {
      margin-bottom: 10px;
      font-size: 50px;
    }
  }
}
</style>