<template>
  <div class="QACreation">
    <header>
      <span>发布论文</span>
      <el-button
        round
        icon="iconfont iconjiatianjia-2"
        @click="
          $router.push({ path: '/found-release-index', query: { type: '11' } })
        "
        >发布</el-button
      >
    </header>
    <div class="content" v-loading="loading">
      <div
        class="list"
        v-for="(item, index) in infoLists"
        :key="index"
        @click="xin.goUrl('/found-details-details?id=' + item.id)"
      >
        <span class="title">{{ item.title }}</span>
        <div class="bottom">
          <div class="content">
            <span class="remark sheng_2" v-html="item.content"></span>
            <div>
              <span
                >{{ item.praise_num }}赞 · {{ item.comment_num }}评论 ·
                {{ item.browse_num }}浏览 ·
                {{ item.create_time | formaTime }}</span
              >
              <span class="delBtn" @click.stop="delInfo(item, index)"
                >删除</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="paging">
        <el-pagination
          hide-on-single-page
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="per_page"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <div v-if="!loading && !infoLists.length" class="null-box">
        <img
          :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
          alt=""
        />
        <div class="title">暂时数据</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      per_page: 10,
      page: 1,
      loading: false,
      type: 11, //1.说说 2.病例分享 3.学术视频 4.词条 5.问答 11.论文
      infoLists: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let res = await this.$personalApi.getPersonalCreate({
        type: this.type,
        page: this.page,
      });
      this.loading = false;
      if (res.code === 200) {
        res.data.lists.data.forEach((item, index) => {
          if (item.images == "[]") {
            item.images = [];
          } else {
            let img = JSON.parse(item.images);
            item.images = img;
          }
        });
        this.infoLists = res.data.lists.data;
        this.total = res.data.lists.total;
        this.per_page = res.data.lists.per_page;
      } else {
        this.$message.error(res.message);
      }
    },
    delInfo(item, i) {
      this.$confirm("是否删除该问答吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showCancelButton: true,
        beforeClose: async (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            let { code, message } = await this.$userApi.postDelDiscover({
              discover_id: item.id,
            });
            if (code == 200) {
              done();
              this.infoLists.splice(i, 1);
              this.xin.message("删除成功！");
            } else {
              this.$message.error(message);
            }
            setTimeout(() => {
              instance.confirmButtonLoading = false;
            }, 300);
          } else {
            done();
          }
        },
      })
        .then(() => {})
        .catch((e) => e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.QACreation {
  background-color: white;
  > header {
    box-sizing: border-box;
    padding: 15px 30px;
    font-size: 24px;
    color: #333333;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    > .el-button {
      margin-left: auto;
      height: 40px;
      border-radius: 40px;
      background-color: $--themeColor;
      border: none;
      color: white;
      border-radius: 6px;
      ::v-deep {
        .iconjiatianjia-2 {
          font-size: 15px;
          color: white;
        }
      }
    }
  }
  > .content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 20px;
    min-height: 400px;
    white-space: pre-line;
    > .list {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 30px 0;
      border-bottom: 1px solid #f2f2f2;
      &:last-child {
        border: none;
      }
      &:hover .delBtn {
        color: #8f8f8f;
      }
      .delBtn {
        margin-left: auto;
        color: #ffffff;
        transition: 0.5s;
        padding: 5px;
      }
      > .title {
        font-size: 24px;
        line-height: 40px;
        color: #333333;
      }
      > .bottom {
        display: flex;
        margin-top: 10px;
        > img {
          width: 145px;
          height: 100px;
          border-radius: 10px;
          object-fit: cover;
          margin-right: 20px;
        }
        > .content {
          flex: 1;
          display: flex;
          flex-direction: column;
          > .remark {
            font-size: 18px;
            line-height: 30px;
            color: #8f8f8f;
          }
          > div {
            display: flex;
            align-items: center;
            margin-top: auto;
            font-size: 18px;
            color: #8f8f8f;
          }
        }
      }
    }
  }
}
</style>
