<template>
  <div class="myEarnings">
    <!-- 收益列表 -->
    <profitList
      v-if="isProfitList && !isWithdrawalDetails"
      @goWithdrawalDetails="goWithdrawalDetails"
      @goSurplus="goSurplus"
    ></profitList>

    <!-- 收益提现 -->
    <surplus
      v-if="!isProfitList && !isWithdrawalDetails"
      @goBack="goBack"
    ></surplus>

    <!-- 提现列表 -->
    <withdrawalDetails
      @backProfitList="backProfitList"
      v-if="isWithdrawalDetails"
    ></withdrawalDetails>
  </div>
</template>

<script>
import profitList from "@components/myProfit/profitList";
import surplus from "@components/myProfit/surplus";
import withdrawalDetails from "@components/myProfit/withdrawalDetails";

export default {
  components: { withdrawalDetails, profitList, surplus },
  data() {
    return {
      isProfitList: true, // 提现列表的显示
      isWithdrawalDetails: false,
    };
  },

  methods: {
    // 去提现
    goSurplus() {
      this.isProfitList = false;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    // 返回
    goBack() {
      this.isProfitList = true;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    // 去账单页面
    goWithdrawalDetails() {
      this.isWithdrawalDetails = true;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    // 返回backProfitList
    backProfitList() {
      this.isWithdrawalDetails = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.myEarnings {
  background-color: #fff;
}
</style>
