<template>
  <div class="page" v-if="userInfo">
    <header>
      <div class="top">
        <img class="bg" src="@image/centerBg.png" alt="个人中心背景图" />
        <section>
          <div class="text" @click="xin.goUrl('/notification?index=2')">
            <span>{{ userParam.commentNum | amount }}</span>
            <span class="label">评论</span>
          </div>
          <div class="text" @click="xin.goUrl('/notification?index=4')">
            <span>{{ userParam.praiseNum | amount }}</span>
            <span class="label">点赞</span>
          </div>
          <div class="avatar">
            <img
              class="avatar"
              :src="
                userInfo.avatar
                  ? userInfo.avatar
                  : require('@image/default.png')
              "
              alt="用户头像"
            />
            <img
              class="icon"
              v-if="userInfo.company && userInfo.company.status == 1"
              src="@image/certification.png"
              alt="认证图标"
            />
          </div>
          <div class="text" @click="xin.goUrl('/userCenter?index=4')">
            <span>{{ userParam.follow | amount }}</span>
            <span class="label">粉丝</span>
          </div>
          <div class="text" @click="xin.goUrl('/userCenter?index=5')">
            <span>{{ userParam.attention | amount }}</span>
            <span class="label">关注</span>
          </div>
        </section>
      </div>
      <div class="bottom">
        <div class="username">
          <img src="@image/identity.png" alt="" />
          {{ userInfo.nickname }}

          <span class="credit" @click="$router.push('/myCreditScore')">
            <span class="round">信</span>
            {{ userInfo.total_profit }}
          </span>
        </div>
        <span class="department" v-if="userInfo.company"
          >{{ userInfo.company.department_name }} |
          {{ userInfo.company.company }}</span
        >
        <span class="button" v-if="userInfo.company">{{
          userInfo.company.title_name
        }}</span>
      </div>
    </header>
    <section>
      <nav>
        <!-- el-menu导航栏左侧或者顶部 -->
        <el-menu
          :default-active="ComponentsIndex"
          active-text-color="#03DBC6"
          @select="ComponentsIndex = $event"
        >
          <template v-for="(navItem, navIndex) in navList">
            <el-submenu
              v-if="navItem.childList"
              :key="navIndex"
              :index="`${navIndex}`"
            >
              <template slot="title">
                <i :class="navItem.icon"></i>
                <span>{{ navItem.label }}</span>
              </template>
              <template
                v-for="(childNavItem, childNavIndex) in navItem.childList"
              >
                <el-menu-item
                  :key="childNavIndex"
                  :index="`${navIndex}-${childNavIndex}`"
                  >{{ childNavItem.label }}</el-menu-item
                >
              </template>
            </el-submenu>
            <el-menu-item v-else :key="navIndex" :index="`${navIndex}`">
              <i :class="navItem.icon"></i>
              <span slot="title">{{ navItem.label }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </nav>
      <div class="content">
        <keep-alive>
          <component :type="type" :is="personalCenterComponents"></component>
        </keep-alive>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

// 批量引入components/personalCenter目录下的所有组件
const components = require
  .context("@components/personalCenter/", false, /.*\.vue/)
  .keys()
  .reduce((components, componentsPath) => {
    const componentsName = componentsPath.replace(/\.\/(.*)\.vue/, "$1");
    components[componentsName] =
      require(`@components/personalCenter/${componentsName}`).default;
    return components;
  }, {});
export default {
  components,
  data() {
    return {
      type: 0,
      navList: [
        {
          label: "我的收益",
          icon: "iconfont wodeshouyi",
          components: "myEarnings",
        },
        {
          label: "草稿箱",
          icon: "iconfont icon-caogaoxiang",
          components: "myDrafts",
        },
        {
          label: "学习轨迹",
          icon: "iconfont xuexiguiji",
          components: "learningTrack",
        },
        {
          label: "我的创作",
          icon: "iconfont shuoshuochuangzuo",
          childList: [
            { label: "发布说说", components: "talkAboutCreation" },
            { label: "病例分享", components: "caseAnalysis" },
            { label: "学术视频", components: "popularScienceVideo" },
            { label: "指南解析", components: "popularScienceEntry" },
            { label: "发布论文", components: "paper" },
            { label: "发布问答", components: "QACreation" },
          ],
        },
        {
          label: "我的调研",
          icon: "iconfont wodetiaoyan",
          components: "myResearch",
          // childList: [
          //   { label: "我的发布", components: "myResearch" },
          //   { label: "我的调研", components: "myResearch", type: 1 },
          //   { label: "电话调研", components: "myResearch", type: 2 },
          //   { label: "面访调研", components: "myResearch", type: 3 },
          // ],
        },
        {
          label: "我的模拟",
          icon: "iconfont wodemoni",
          components: "mySimulation",
        },
        {
          label: "诊疗日志",
          icon: "iconfont zhenliaorizhi",
          components: "diagnosisAndTreatmentLog",
        },
        {
          label: "我的患者",
          icon: "iconfont wodehuanzhe",
          components: "myPatient",
        },
      ],
      ComponentsIndex: "0",
    };
  },
  created() {
    this.getPersonal();
    this.getUserInfo();
    let index = this.$route.query.index;
    if (index) {
      let type = index.split("-");
      if (type.length == 2) {
        this.type = type[1];
      }

      this.ComponentsIndex = index;
    }
  },

  watch: {
    $route(val) {
      let index = val.query.index;
      if (index) {
        this.ComponentsIndex = index;
      }
    },
    ComponentsIndex(val) {
      let type = val.split("-");
      if (type.length == 2) {
        this.type = type[1];
      }
      // this.$route.query.index = val;
      const newUrl = `#/personalCenter?index=${val}`;
      window.history.replaceState("", "", newUrl);
    },
  },

  methods: {
    ...mapActions("userManagement", ["getPersonal", "getUserInfo"]),
  },
  computed: {
    ...mapState("userManagement", ["userInfo", "userParam"]),
    personalCenterComponents() {
      return this.navList[this.ComponentsIndex.split("-")[0]].childList
        ? this.navList[this.ComponentsIndex.split("-")[0]].childList[
            this.ComponentsIndex.split("-")[1]
          ].components
        : this.navList[this.ComponentsIndex.split("-")[0]].components;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.page {
  background-color: #f2f2f2;
  > header {
    display: flex;
    flex-direction: column;
    background-color: white;
    > .top {
      height: 110px;
      display: flex;
      z-index: 1;
      > .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      > section {
        box-sizing: border-box;
        display: flex;
        padding: 0 170px 15px;
        justify-content: space-between;
        > .text {
          cursor: pointer;
          margin-top: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          > span {
            line-height: 30px;
            font-size: 24px;
            &.label {
              font-size: 14px;
            }
          }
        }
        > .avatar {
          margin-top: auto;
          width: 150px;
          height: 150px;
          bottom: -30px;
          border-radius: 50%;
          border: 2px solid white;
          background-color: white;
          cursor: pointer;
          > .avatar {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
          > .icon {
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 0;
            right: 0;
            object-fit: contain;
          }
        }
      }
    }
    > .bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 30px;

      > .username {
        margin-top: 85px;
        font-size: 36px;
        line-height: 36px;
        color: #333333;

        > img {
          width: 25px;
        }

        .credit {
          cursor: pointer;
          box-sizing: border-box;
          position: relative;
          display: inline-block;
          height: 25px;
          width: 85px;
          line-height: 25px;
          padding-left: 20px;
          text-align: center;
          border-radius: 50px;
          color: #fff;
          font-weight: 600;
          font-style: italic;
          font-size: 18px;
          background-image: radial-gradient(circle, #ffce00 20%, #ed8000 80%);

          .round {
            position: absolute;
            left: -3px;
            top: -3px;
            width: 28px;
            height: 28px;
            text-align: center;
            line-height: 28dpx;
            font-weight: 400;
            border-radius: 50%;
            background-image: radial-gradient(circle, #ffce00 20%, #ed8000 80%);
            font-style: normal;
            border: 1px solid #fff;
          }
        }
      }
      > .department {
        margin-top: 10px;
        font-size: 18px;
        line-height: 25px;
        color: #333333;
      }
      > .button {
        cursor: pointer;
        margin-top: 20px;
        font-size: 18px;
        line-height: 34px;
        box-sizing: border-box;
        border: 1px solid $--themeColor;
        color: $--themeColor;
        height: 36px;
        padding: 0 19px;
        background-color: white;
        border-radius: 6px;
      }
    }
  }
  > section {
    margin-top: 30px;
    display: flex;
    > nav {
      width: 196px;
      height: max-content;
      background-color: white;
      position: sticky;
      top: 100px;
      ::v-deep {
        .el-menu {
          .el-menu--inline {
            overflow: hidden;
            .el-menu-item {
              padding-left: 50px !important;
            }
          }
          .el-menu-item,
          .el-submenu {
            span {
              margin-left: 10px;
            }
          }
        }
        .is-active {
          &::before {
            position: absolute;
            left: 0;
            top: calc((100% - 35px) / 2);
            content: "";
            display: block;
            width: 5px;
            height: 35px;
            background-color: $--themeColor;
          }
        }
      }
    }
    > .content {
      flex: 1;
      margin-left: 20px;
      height: max-content;
      overflow: hidden;
    }
  }
}
</style>
