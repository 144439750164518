<template>
  <div class="draft" v-loading="loading">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!-- 发布说说 -->
      <el-tab-pane :label="'发布说说(' + tabCount.type1 + ')'" name="1">
        <section v-if="draftLists.length">
          <div class="content_warp" v-for="item in draftLists" :key="item.id">
            <div class="top">
              <el-checkbox
                v-model="checked"
                :key="item.id"
                :label="item.id"
                @change="handleCheckedChange"
              ></el-checkbox>
              <span class="time">{{ item.create_time | formaTime }}</span>
              <el-button type="primary" @click="draftEdit(item.id, item.type)">
                重发
              </el-button>
            </div>
            <div class="content">
              <div class="img" v-if="item.images && item.images.length">
                <div
                  class="img_box"
                  v-for="(it, idx) in item.images"
                  :key="idx"
                >
                  <img :src="it" alt="图片" />
                </div>
              </div>
              <div class="word" v-html="item.content"></div>
            </div>
          </div>

          <div class="bottom">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
              >全选</el-checkbox
            >
            <el-button @click="findDiscoberDelDraft" type="danger"
              >删除</el-button
            >
          </div>
        </section>
        <div class="null-box" v-else>
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
          />
          <div class="title">暂无数据</div>
        </div>
      </el-tab-pane>

      <!-- 病例分享 -->
      <el-tab-pane :label="'病例分享(' + tabCount.type2 + ')'" name="2">
        <section v-if="draftLists.length">
          <div class="content_warp" v-for="item in draftLists" :key="item.id">
            <div class="top">
              <el-checkbox
                v-model="checked"
                :key="item.id"
                :label="item.id"
                @change="handleCheckedChange"
              ></el-checkbox>
              <span class="time">{{ item.create_time | formaTime }}</span>
              <el-button type="primary" @click="draftEdit(item.id, item.type)">
                重发
              </el-button>
            </div>
            <div class="content">
              <h4>{{ item.title }}</h4>
              <div class="word" v-html="item.content"></div>
            </div>
          </div>

          <div class="bottom">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
              >全选</el-checkbox
            >
            <el-button @click="findDiscoberDelDraft" type="danger"
              >删除</el-button
            >
          </div>
        </section>
        <div class="null-box" v-else>
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
          />
          <div class="title">暂无数据</div>
        </div>
      </el-tab-pane>

      <!-- 学术视频 -->
      <el-tab-pane :label="'学术视频(' + tabCount.type3 + ')'" name="3">
        <section v-if="draftLists.length">
          <div class="content_warp" v-for="item in draftLists" :key="item.id">
            <div class="top">
              <el-checkbox
                v-model="checked"
                :key="item.id"
                :label="item.id"
                @change="handleCheckedChange"
              ></el-checkbox>
              <span class="time">{{ item.create_time | formaTime }}</span>
              <el-button type="primary" @click="draftEdit(item.id, item.type)">
                重发
              </el-button>
            </div>
            <div class="content">
              <h4>{{ item.title }}</h4>
              <div class="detail">
                <div class="img_box" v-if="item.video_img">
                  <img :src="item.video_img" alt="" />
                  <div class="label">
                    <i class="el-icon-caret-right"></i>
                  </div>
                </div>

                <div class="word" v-html="item.content"></div>
              </div>
            </div>
          </div>

          <div class="bottom">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
              >全选</el-checkbox
            >
            <el-button @click="findDiscoberDelDraft" type="danger"
              >删除</el-button
            >
          </div>
        </section>
        <div class="null-box" v-else>
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
          />
          <div class="title">暂无数据</div>
        </div>
      </el-tab-pane>

      <!-- 指南解析 -->
      <el-tab-pane :label="'指南解析(' + tabCount.type4 + ')'" name="4">
        <section v-if="draftLists.length">
          <div class="content_warp" v-for="item in draftLists" :key="item.id">
            <div class="top">
              <el-checkbox
                v-model="checked"
                :key="item.id"
                :label="item.id"
                @change="handleCheckedChange"
              ></el-checkbox>
              <span class="time">{{ item.create_time | formaTime }}</span>
              <el-button type="primary" @click="draftEdit(item.id, item.type)">
                重发
              </el-button>
            </div>
            <div class="content">
              <h4>{{ item.title }}</h4>
              <div class="science">
                <p v-html="item.content"></p>
                <p>
                  疾病 : <span>{{ item.disease }}</span>
                </p>
                <p>
                  搜索词 : <span>{{ item.disease }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="bottom">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
              >全选</el-checkbox
            >
            <el-button @click="findDiscoberDelDraft" type="danger"
              >删除</el-button
            >
          </div>
        </section>
        <div class="null-box" v-else>
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
          />
          <div class="title">暂无数据</div>
        </div>
      </el-tab-pane>

      <!-- 发布论文 -->
      <el-tab-pane :label="'发布论文(' + tabCount.type11 + ')'" name="11">
        <section v-if="draftLists.length">
          <div class="content_warp" v-for="item in draftLists" :key="item.id">
            <div class="top">
              <el-checkbox
                v-model="checked"
                :key="item.id"
                :label="item.id"
                @change="handleCheckedChange"
              ></el-checkbox>
              <span class="time">{{ item.create_time | formaTime }}</span>
              <el-button type="primary" @click="draftEdit(item.id, item.type)">
                重发
              </el-button>
            </div>
            <div class="content">
              <h4>{{ item.title }}</h4>
              <div v-html="item.content" class="science"></div>
            </div>
          </div>

          <div class="bottom">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
              >全选</el-checkbox
            >
            <el-button @click="findDiscoberDelDraft" type="danger"
              >删除</el-button
            >
          </div>
        </section>
        <div class="null-box" v-else>
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
          />
          <div class="title">暂无数据</div>
        </div>
      </el-tab-pane>

      <!-- 发布问答 -->
      <el-tab-pane :label="'发布问答(' + tabCount.type5 + ')'" name="5">
        <section v-if="draftLists.length">
          <div class="content_warp" v-for="item in draftLists" :key="item.id">
            <div class="top">
              <el-checkbox
                v-model="checked"
                :key="item.id"
                :label="item.id"
                @change="handleCheckedChange"
              ></el-checkbox>
              <span class="time">{{ item.create_time | formaTime }}</span>
              <el-button type="primary" @click="draftEdit(item.id, item.type)">
                重发
              </el-button>
            </div>
            <div class="content">
              <div class="img" v-if="item.images && item.images.length">
                <div
                  class="img_box"
                  v-for="(it, idx) in item.images"
                  :key="idx"
                >
                  <img :src="it" alt="图片" />
                </div>
              </div>
              <div class="word" v-html="item.content"></div>
            </div>
          </div>

          <div class="bottom">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
              >全选</el-checkbox
            >
            <el-button @click="findDiscoberDelDraft" type="danger"
              >删除</el-button
            >
          </div>
        </section>
        <div class="null-box" v-else>
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
          />
          <div class="title">暂无数据</div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 删除信息对话框 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>确定要删除选中的内容吗?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="delComfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      activeName: "1",
      checkAll: false,
      checked: [],
      checkedOption: [],
      tabCount: {
        type1: 0,
        type2: 0,
        type3: 0,
        type4: 0,
        type5: 0,
        type11: 0,
      },
      draftLists: [],
      type: 1,
      dialogVisible: false,
    };
  },

  created() {
    this.findDiscobergetDraftList();
  },

  methods: {
    // tab栏点击
    handleClick(tab, event) {
      this.type = tab.name;
      this.findDiscobergetDraftList();
      this.checkAll = false;
      this.checked = [];
      this.checkedOption = [];
    },

    // 单选
    handleCheckedChange() {
      this.checkAll = this.checkedOption.length == this.checked.length;
    },

    // 全选
    handleCheckAllChange(val) {
      this.checked = val ? this.checkedOption : [];
    },

    // 草稿箱列表
    async findDiscobergetDraftList() {
      let res = await this.$findApi.findDiscobergetDraftList({
        type: this.type,
      });
      if (res.code == 200) {
        this.loading = false;
        this.tabCount = res.data.count;
        this.draftLists = res.data.list;
        this.checkedOption = this.draftLists.map((item) => {
          return item.id;
        });
        this.draftLists.forEach((item) => {
          item.images = JSON.parse(item.images);
        });
      } else {
        this.loading = false;
        this.$message.error(res.message);
      }
    },

    // 删除草稿箱
    findDiscoberDelDraft() {
      if (!this.checked.length) {
        this.$message("至少选择一条删除内容");
        return;
      }
      this.dialogVisible = true;
    },

    // 删除确定
    async delComfirm() {
      this.dialogVisible = false;
      let id = this.checked.join(",");
      let res = await this.$findApi.findDiscoberDelDraft({
        id: id,
      });
      if (res.code == 200) {
        this.$message.success("删除成功");
        this.findDiscobergetDraftList();
      } else {
        this.$message.error(res.message);
      }
    },

    // 草稿箱详情
    draftEdit(id, type) {
      this.$router.push(
        "/found-release-index?id=" + id + "&type=" + type + "&from=draft"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.draft {
  background-color: white;

  /deep/ .el-tabs__nav-wrap {
    padding: 15px 35px 0;
  }

  /deep/ .el-tabs .el-tabs__item.is-active {
    transform: none;
  }

  /deep/ .el-tabs__content {
    padding: 0 40px 30px;
  }

  /deep/ .el-checkbox__inner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
    background: $--themeColor !important;
  }

  /deep/ .el-checkbox__inner::after {
    top: 3px;
    left: 7px;
    height: 8px;
  }

  /deep/ .el-checkbox .is-checked > .el-checkbox__inner::after {
    border-color: #fff !important;
  }

  .content_warp {
    /deep/ .el-checkbox__label {
      display: none;
    }

    .top {
      padding-top: 10px;
      width: 900px;
      height: 50px;
      .time {
        font-size: 14px;
        color: #8f8f8f;
        margin-left: 20px;
      }

      button {
        float: right;
      }
    }

    .content {
      padding: 10px 200px 30px 20px;
      font-size: 18px;
      border-bottom: 1px solid #d9d9d9;
      *zoom: 1;
      &::after {
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
      }

      h4 {
        font-size: 20px;
        margin-bottom: 20px;
      }

      .img_box {
        float: left;
        width: 150px;
        height: 120px;
        margin-right: 20px;
        position: relative;
        overflow: hidden;
        border-radius: 5px;
        margin-bottom: 10px;

        img {
          width: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .word {
        float: left;
        margin-top: 10px;
        max-width: 65%;
        line-height: 28px;
      }

      .detail {
        display: flex;

        .img_box {
          float: left;
          width: 150px;
          height: 120px;
          margin-right: 20px;
          position: relative;
          overflow: hidden;
          border-radius: 5px;

          img {
            width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          .label {
            position: absolute;
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.3);
            color: white;
            box-sizing: border-box;
            width: 60px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 26px;
            bottom: 10px;
            left: 70px;
          }
        }
      }

      .science {
        p {
          margin-bottom: 15px;
          color: #666;
        }
      }
    }
  }

  .bottom {
    margin-top: 20px;
    width: 900px;
    height: 50px;

    button {
      float: right;
    }
  }
}

/deep/.el-dialog__body {
  padding: 40px 10px;
  text-align: center;
  font-size: 18px;
}

/deep/ .el-dialog__footer .dialog-footer button {
  width: 60px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0;
}
</style>
