<template>
  <div class="page">
    <div>暂未开放</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.page {
  background-color: white;
  > div {
    font-size: 36px;
    padding: 20px;
    margin: 0 auto;
    margin-top: 80px;
  }
}
</style>