<template>
  <div class="myResearch">
    <header>
      <!-- <span>{{ type | typeName }}</span> -->
      <span>我的调研</span>
      <!-- <el-button v-if="type == 0" round icon="iconfont iconjiatianjia-2"
        >发布</el-button
      > -->
    </header>
    <div class="content" v-loading="loading">
      <div v-if="!loading && !survyProject.length" class="null-box">
        <img
          :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
          alt=""
          srcset=""
        />
        <div class="title">您还没有参加调研哦</div>
      </div>
      <a
        :style="{ pointerEvents: item.payment_link ? 'auto' : 'none' }"
        :href="item.payment_link || 'javascript:return false;'"
        target="_blank"
        class="list"
        v-for="item in survyProject"
        :key="item.id"
      >
        <div class="img">
          <img class="banner2" :src="item.pic_url" />
          <div class="mark" :style="item.pro_type | typeColor">
            <span>{{ item.pro_type | typeName }}</span>
          </div>
        </div>
        <div class="left-onLine-right">
          <span class="sheng">{{ item.project_id }}</span>
          <span class="pay_status" :style="item.type | payColor">
            {{ item.type_name }}
          </span>
          <span class="subTitle sheng_2">{{ item.pro_name }}</span>
          <div class="right-button">
            <span class="blue left-btn">{{ item.min_time_limit }}分钟</span>
            <span class="blue left-btn" v-if="item.point">
              ¥{{ item.point }}
            </span>
          </div>
        </div>
      </a>
      <div class="paging">
        <el-pagination
          hide-on-single-page
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="per_page"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,
      total: 0,
      per_page: 1,
      page: 1,
      survyProject: [],
    };
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },
  props: {
    type: {
      type: [String, Number],
      default: 0,
    },
  },
  activated() {
    this.mySurvyProject();
  },
  watch: {
    // 监听type(type改变接口重新请求)
    type() {
      this.survyProject = [];
      this.mySurvyProject();
    },
  },
  filters: {
    // 问卷类型
    typeName(val) {
      switch (Number(val)) {
        case 1:
          val = "在线调研";
          break;
        case 2:
          val = "电话调研";
          break;
        case 3:
          val = "电话回访";
          break;
        case 4:
          val = "面访调研";
          break;
        case 5:
          val = "小组座谈";
          break;
        case 6:
          val = "精准推送";
          break;
        default:
          val = "在线调研";
          break;
      }
      return val;
    },
    // 问卷类型颜色
    typeColor(val) {
      switch (Number(val)) {
        case 1:
          val = "background:#F0CB2D";
          break;
        case 2:
          val = "background:#E60012";
          break;
        case 3:
          val = "background:#1DBAFF";
          break;
        case 4:
          val = "background:#03DBC6";
          break;
        case 5:
          val = "background:#0A56BE";
          break;
        case 6:
          val = "background:#971FFD";
          break;
        default:
          val = "background:#F0CB2D";
          break;
      }
      return val;
    },
    // 支付颜色 type:1、待审核 2、待提现 3、已收款 4、重新提现 5、待支付 6、条件不符 7、配额已满
    payColor(val) {
      switch (Number(val)) {
        case 1:
          val = "color:#FD7E1F";
          break;
        case 2:
          val = "color:#D20010";
          break;
        case 3:
          val = "color:#1B252F";
          break;
        case 4:
          val = "color:#D20010";
          break;
        case 5:
          val = "color:#FD7E1F";
          break;
        case 6:
          val = "color:#1B252F";
          break;
        case 7:
          val = "color:#1B252F";
          break;
      }
      return val;
    },
  },
  created() {
    this.mySurvyProject();
  },
  methods: {
    // 分页()
    handleCurrentChange(val) {
      this.page = val;
      this.mySurvyProject();
    },

    // 在线调研1
    async mySurvyProject() {
      this.loading = true;
      let token = localStorage.getItem("token");
      // if (this.type != 0) {
      let { data, code, message } = await this.$researchApi.survyProject({
        token,
        type: 4,
        proType: "",
        proStatus: 2,
        page: this.page - 1,
      });
      if (code == 200) {
        this.survyProject = data.data;
        this.per_page = +data.page_size;
        this.total = +data.total_num;
      } else {
        this.$message.error(message);
      }
      // }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.myResearch {
  background-color: white;
  > header {
    box-sizing: border-box;
    padding: 15px 30px;
    font-size: 24px;
    color: #333333;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    > .el-button {
      margin-left: auto;
      height: 40px;
      border-radius: 40px;
      background-color: $--themeColor;
      border: none;
      color: white;
      border-radius: 6px;
      ::v-deep {
        .iconjiatianjia-2 {
          font-size: 15px;
          color: white;
        }
      }
    }
  }
  > .content {
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    min-height: 400px;

    a {
      text-decoration: none;
    }
    > .list {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow: hidden;
      padding: 30px 0;
      box-sizing: border-box;
      border-bottom: 1px solid #f2f2f2;
      .right-button {
        margin-top: auto;
        .blue {
          color: #fff;
          height: 44px;
          padding: 10px 30px;
          border-radius: 5px;
          box-sizing: border-box;
          font-size: 18px;
          line-height: 22px;
          cursor: pointer;
        }
        .left-btn {
          background: linear-gradient(90deg, $--themeColor, #46cbca);
          float: left;
          margin-right: 30px;
        }
        .recommend-yellow {
          background: #f6e06a;
          color: #333;
          float: right;
        }
        .close-btn {
          background: #e60012;
          margin-left: 30px;
          float: right;
        }
      }
      &:nth-child(2n) {
        > .left-onLine-right {
          > .right-button {
            > .recommend-yellow {
              background: #ffa364;
            }
          }
        }
      }
      > .img {
        width: 150px;
        height: 150px;
        overflow: hidden;
        > .banner2 {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .mark {
          width: 120px;
          height: 24px;
          transform: rotate(307deg); //旋转
          position: absolute;
          text-align: center;
          left: -35px;
          top: 25px;
          line-height: 24px;
          color: #ffffff;
          font-size: 12px;
        }
      }

      > .left-onLine-right {
        position: relative;
        width: calc(100% - 150px - 30px);
        // flex-direction: row-reverse;
        display: flex;
        flex-direction: column;
        color: #333333;
        font-size: 24px;
        > .subTitle {
          font-size: 18px;
          color: #8f8f8f;
          margin-top: 10px;
          line-height: 1.4;
        }

        .sheng {
          width: 75%;
        }

        .pay_status {
          position: absolute;
          right: 20px;
          font-size: 20px;
          color: #b1adad;
        }
      }
    }
  }
}
</style>
