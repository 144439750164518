<template>
  <div class="mySimulation">
    <header>我的模拟</header>
    <div class="table" v-loading="loading">
      <el-table
        :data="simulationList"
        style="width: 100%"
        @cell-click="readDetail"
        v-if="simulationList.length"
      >
        <el-table-column prop="caption" label="试卷名称" width="450">
        </el-table-column>
        <el-table-column prop="total_minute" label="考试时间">
          <template slot-scope="scope">
            <span>{{ scope.row.total_minute }}分钟</span>
          </template>
        </el-table-column>
        <el-table-column prop="max_fraction" label="最高分">
          <template slot-scope="scope">
            <span>{{ scope.row.max_fraction }}分</span>
          </template>
        </el-table-column>
        <el-table-column prop="avg" label="平均分">
          <template slot-scope="scope">
            <span>{{ scope.row.avg.toFixed(1) }}分</span>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="次数">
          <template slot-scope="scope">
            <span>{{ scope.row.number }}次</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="foot-page">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page" :page-size="10" layout="prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div> -->
      <div class="null-box" v-else>
        <img
          :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
          alt=""
        />
        <div class="title">暂无数据</div>
      </div>
    </div>
    <el-dialog
      width="940px"
      custom-class="showDetail"
      :visible.sync="showDetail"
    >
      <el-table :data="simulationList" style="width: 100%">
        <el-table-column prop="time" label="模拟时间"> </el-table-column>
        <el-table-column prop="name" label="答对"> </el-table-column>
        <el-table-column prop="max" label="答错"> </el-table-column>
        <el-table-column prop="average" label="得分"> </el-table-column>
        <el-table-column prop="frequency" label="操作"> </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      simulationList: [],
      showDetail: false,
      loading: false,
    };
  },
  async activated() {
    this.loading = true;
    await this.getsimulationLists();
    this.loading = false;
  },
  methods: {
    async getsimulationLists() {
      let res = await this.$newsApi.getsimulationLists({ title: this.title });
      if (res.code == 200) {
        this.simulationList = res.data;
      } else {
        this.$message.error(res.message);
      }
    },
    readDetail(e) {
      // this.showDetail = true
      this.$router.push({
        path: "/index-mockExam-simulationHistory",
        query: { serial_code: e.serial_code },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.mySimulation {
  .foot-page {
    height: 40px;
    text-align: center;
    width: 100%;
    margin-top: 40px;
  }
  background-color: white;
  ::v-deep {
    .showDetail {
      border-radius: 0;
      box-sizing: border-box;
      padding: 0 20px;
    }
  }
  > header {
    box-sizing: border-box;
    padding: 15px 30px;
    font-size: 24px;
    color: #333333;
    border-bottom: 1px solid #d9d9d9;
  }
  > .table {
    box-sizing: border-box;
    padding: 30px;
    ::v-deep {
      .el-tabs__header {
        margin-bottom: 22px;
        .el-tabs__nav-scroll {
          padding: 0 30px;
          font-size: 24px !important;
          height: 60px;
          line-height: 60px;
          .is-active {
            color: $--themeColor;
            font-weight: 400;
            transform: scale(1);
          }
        }
      }
      .el-tabs__content {
        box-sizing: border-box;
        padding: 0 27px;
      }
      tbody {
        cursor: pointer;
      }
      tr {
        font-size: 16px;
        line-height: 22px;
        color: #333333;
        > th {
          background-color: #fafafa;
          height: 60px;
          text-align: center;
        }
        > td {
          box-sizing: border-box;
          padding: 20px 0;
          text-align: center;
        }
      }
    }
  }
}
</style>
